import React from 'react';
import FilingCardCompanySearch from '../FilingCards/FilingCardCompanySearchV2'; // Make sure to import this component

const FilingList = ({ filingData, handleFilingClick }) => {
    return (
        <div className="bg-white shadow-sm rounded-md overflow-hidden">
            {filingData.map((exhibitWithTable, index) => (

                <div
                    key={`${exhibitWithTable.Exhibit.id}-${index}`}
                    className="flex flex-col bg-gray-600 hover:bg-gray-500 px-0 cursor-pointer rounded-sm"
                    onClick={() => handleFilingClick(exhibitWithTable)}
                >
                    <FilingCardCompanySearch filing={exhibitWithTable.Exhibit.edges.sec_filing} allSmall={true}/>
                </div>
            ))}
        </div>
    );
};

export default FilingList;