import React, {useState} from 'react';
import { RiMenu3Fill } from 'react-icons/ri'; // Import icons from react-icons
import LogoBoast from './LogoBoast';
import UserMenu from "./UserMenuDropDown";
import {FaTwitter} from "react-icons/fa";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <div className="bg-nav-bar-theme text-nav-bar-text-color py-4 px-6 border border-r-0 border-l-0 border-t-0 border-b-1 border-gray-200">
            <div className="flex justify-between items-center">
                <div className="flex">
                    <LogoBoast/>
                </div>
                <div className="hidden lg:flex items-center">
                    <div className="flex-grow"></div> {/* Spacer */}
                    <div className="flex items-center">
                        <a href="https://twitter.com/FwdQuarter"
                           className="text-gray-300 hover:text-gray-800 mr-4"
                           target="_blank"
                           rel="noopener noreferrer">
                            <FaTwitter size={25} />
                        </a>
                        <UserMenu />
                    </div>
                </div>
                <button
                    type="button"
                    className="rounded-full bg-nav-bar-theme p-2 lg:hidden"
                    title="Menu"
                    onClick={toggleMenu}
                >
                    <RiMenu3Fill />
                </button>
            </div>

            {isMenuOpen && (
                <div className="lg:hidden">
                    <div className="flex items-center">
                        <a href="https://twitter.com/FwdQuarter"
                           className="text-gray-300 hover:text-gray-800 mr-4"
                           target="_blank"
                           rel="noopener noreferrer">
                            <FaTwitter size={25} />
                        </a>
                         <UserMenu />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Navbar;