import React from 'react';
import { Link } from 'react-router-dom';

const CompanyButton = ({ company, ticker }) => {
    return (
        <div className="px-4 mb-4">
            <Link
                to={`/company/${ticker}`}
                className="block transition-colors duration-200"
            >
                <div className="flex items-center justify-between px-4 py-3 bg-gray-900 hover:bg-gray-800 text-white rounded-lg shadow-sm">
                    <div className="flex items-center">
                        <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 4H20V20H4V4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4 9H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="font-medium text-sm">{company?.name || 'Company Overview'}</span>
                    </div>
                    <span className="bg-gray-700 px-2 py-1 rounded text-xs font-semibold">{ticker}</span>
                </div>
            </Link>
        </div>
    );
};

export default CompanyButton;