// About.js
import NavBarSmall from "./NavBarSmall";
import React from 'react';
import Footer from "./Footer";

function About() {
    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <div className="lg:w-1/2 md:w-4/5 mx-auto">
                <div className="bg-cover bg-center">
                    <div className="p-8 my-20">
                        <div className="bg-filing-menu-background-light p-8 pt-6 rounded-sm shadow text-gray-950 ">
                            <div className="text-3xl p-4 font-bold">About</div>
                            <p className="p-4 text-md"><span className="font-semibold">Modeledge</span> is a boutique data extraction and research service.</p>
                            <p className="p-4 text-md">Our philosophy is to design tools which limit distraction and cognitive overhead. We are an application layer AI company.</p>
                            <p className="p-4 text-md">For more information, please reach out via our <a href="/contact" className="text-blue-500 hover:text-blue-600">contact</a> page.</p>
                            <p className="p-4 text-md">You may also email us directly at customers@modeledge.ai</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default About;
