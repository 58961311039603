import React from 'react';
import FilingCardCompanySearch from '../FilingCards/FilingCardCompanySearchV2'; // Make sure to import this component

const FilingList = ({ filingData, category, handleFilingClick }) => {
    return (
        <div className="bg-white shadow-sm rounded-md overflow-hidden">
            {filingData[category].map((filing, index) => (
                <div
                    key={filing.eid + "-" + index}
                    onClick={() => handleFilingClick(filing)}
                    className="flex flex-col bg-gray-600 hover:bg-gray-500 px-0 cursor-pointer rounded-sm"
                >
                    <FilingCardCompanySearch
                        filing={filing}
                        isLastItem={index === filingData[category].length - 1}
                    />
                </div>
            ))}
        </div>
    );
};

export default FilingList;