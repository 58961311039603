import React from 'react';

export const Button = ({ children, className, ...props }) => {
    return (
        <button
            className={`px-3 py-1 bg-neutral-900 text-white rounded hover:bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-opacity-50 ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

