import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';

const TextSearchInput = ({ query, setQuery, handleSearch, searchInputRef, placeholder }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="relative mb-1">
            <div className="relative">
                <input
                    ref={searchInputRef}
                    type="text"
                    placeholder={placeholder}
                    className="w-full pr-10 pl-4 py-2.5 text-sm text-gray-800 bg-gray-100 border-b border-gray-300 focus:outline-none focus:border-neutral-500 focus:ring-0 focus:ring-neutral-500 transition-colors duration-200"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    onClick={handleSearch}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                    aria-label="Execute search"
                >
                    <SearchIcon className="h-4 w-4" />
                </button>
            </div>
        </div>
    );
};

export default TextSearchInput;