// CompanyInsider.js
import { API_URL } from '../config.js';
import NavBarSmall from "./NavBarSmall";
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import {useNavigate, useParams} from 'react-router-dom';
import FooterSmall from "./FooterSmall";
import {useAuth} from "../firebase/AuthContext";
import TableSearchDiv from "./TableSearchDiv";
import SimilarCompanies from "./SimilarCompanies/SimilarCompanies";
import TransactionsTable from "./InsiderTransactions/Insiders";

function arrayToStringWithCommas(arr) {
    if (!Array.isArray(arr)) {
        return arr
    }

    if (!arr.every(item => typeof item === 'string')) {
        throw new Error("Array contains non-string elements");
    }

    if (arr.length === 0) {
        return "";
    }

    const sanitizedArray = arr.map(item => sanitizeString(item));

    return sanitizedArray.join(", ");
}

function sanitizeString(str) {
    return str.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}
function CompanyInsider() {
    const [companyData, setCompanyData] = useState(null);
    const { ticker } = useParams();
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleFollowClick = () => {
        if (currentUser && !isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/add/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else if (currentUser && isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/remove/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else {
            navigate('/login')
        }
    };

    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};
        axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers })
            .then(response => {
                setIsFollowing(response.data.isFollowing)
                setCompanyData(response.data.company); // Update this line
            })
            .catch(error => {
                console.error('Error fetching company data:', error);
            });
    }, [ticker, currentUser]);



    useEffect(() => {
        //
    }, [isFollowing]);

    if (!companyData) {
        return (
            <>
                <div className="bg-code-light-black bg-center min-h-screen">
                <NavBarSmall />
                <div className="bg-filing-menu-background bg-center h-screen">

                </div>
            </div>
            </>)
    }



    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="lg:w-4/5 md:w-4/5 mx-auto">
                <div className="md:px-4 lg:px-4 py-1 sm:p-1">
                    <div>
                        <div className="container mx-auto py-4 lg:mt-2 ms:mt-1 px-1 md:px-0">
                            <div><h1 className="text-3xl text-code-egg-shell-white font-bold inline">{companyData.name} ({arrayToStringWithCommas(companyData.tickers)})</h1>
                                <button
                                    className={`ml-4 p-1 rounded text-xs font-semibold ${isFollowing ? 'bg-company-button text-bg-gray-100' : 'bg-gray-500 text-white'} text-white`}
                                    onClick={handleFollowClick}
                                >
                                    {isFollowing ? 'Unfollow' : 'Follow'}
                                </button>
                              </div>

                            <p className="text-sm text-gray-400">{companyData.businessCity}, {companyData.businessState}</p>
                        </div>
                    </div>
                    <SimilarCompanies ticker={ticker} />
                    { companyData.hasTables ?
                        <div className="container mx-auto pt-2 px-1 md:px-0">
                            <div className="flex justify-start items-center">
                                <TableSearchDiv ticker={ticker} />
                            </div>
                        </div> : <></>
                    }
                </div>
                <div className="container mx-auto md:p-4 lg:p-4 lg:pt-1 sm:p-1 ">
                    <div className="bg-gray-200 p-4">
                        <h2 className="text-lg font-bold mb-2 px-4">Insider Transactions - By Transaction</h2>
                    <TransactionsTable ticker={ticker} currentUser={currentUser} />
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    )
}

export default CompanyInsider;
