import React, { useState } from 'react';
import { RiMenu3Fill } from 'react-icons/ri';
import UserMenu from "./UserMenuDropDown";
import SearchBarV2 from "./SearchBar/SearchBarV2";

const NavbarSmallV2 = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="bg-filing-menu-background text-nav-bar-text-color p-2 border-b border-gray-500">

            <div className="flex justify-between items-center">
                <SearchBarV2 />

                <div className="lg:hidden">
                    <button
                        type="button"
                        className="rounded-full bg-nav-bar-theme p-2"
                        title="Menu"
                        onClick={toggleMenu}
                    >
                        <RiMenu3Fill />
                    </button>
                </div>

                <div className="hidden lg:flex items-center space-x-4">
                    <UserMenu />
                </div>
            </div>

            {isMenuOpen && (
                <div className="flex flex-col items-start lg:hidden mt-2">
                    <UserMenu />
                </div>
            )}
        </div>
    );
};

export default NavbarSmallV2;
