import React, { useState } from 'react';
import NavBarSmall from "./NavBarSmall";
import Footer from "./Footer";
import {API_URL} from "../config";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/api/tools/contact-us`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log("Form submitted successfully");
                setIsSubmitted(true);
            } else {
                console.log("Form submission failed");
                // Handle errors
            }
        } catch (error) {
            console.error("Failed to submit form", error);
            // Handle errors
        }
    };

    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen flex flex-col justify-between">
            <NavBarSmall />
            <div className="lg:w-1/2 md:w-4/5 mx-auto">
                <div className="p-8 my-20">
                    <div className={`bg-filing-menu-background-light p-10 pt-6 rounded-xl shadow text-gray-950 ${isSubmitted ? 'block' : 'hidden'}`}>
                        <h2 className="text-2xl font-semibold text-center mb-4">Thank you!</h2>
                        <p className="text-center">Your message has been sent successfully.</p>
                    </div>
                    <div className={`bg-filing-menu-background-light p-10 pt-6 rounded-xl shadow text-gray-950 ${!isSubmitted ? 'block' : 'hidden'}`}>
                        <h2 className="text-2xl font-semibold text-center mb-4">Contact Modeledge</h2>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium">Name:</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium">Message:</label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    rows="6"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="w-full px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:bg-blue-600"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;