// Company.js
import { API_URL } from '../config.js';
import NavBarSmall from "./NavBarSmall";
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import {useNavigate, useParams} from 'react-router-dom';
import FilingCategory from "./FilingCatagory";
import FooterSmall from "./FooterSmall";
import {useAuth} from "../firebase/AuthContext";
import SimilarCompanies from "./SimilarCompanies/SimilarCompaniesV3";
import FilingCategoryOwnershipFilings from "./FilingCatagoryOwnership";
import {arrayToStringWithCommasAndLengthLimitIntelligent} from "../values"
import CompanySearchBars from "./CompanySearchBars";
import CompanyDescription from "./CompanyQuery/CompanyDescription";
import TransactionsTableTGGroup from "./InsiderTransactions/InsidersTGGroup";
import Transcripts from "./Transcript/TranscriptPage";

function Company() {
    const [activeTab, setActiveTab] = useState('Filings');
    const [companyData, setCompanyData] = useState(null);
    const { ticker } = useParams();
    const [filingsData, setFilingsData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};

        Promise.all([
            axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers }),
            axiosInstance.get(`${API_URL}/api/company/filings/${ticker}`, { headers })
        ]).then(([companyResponse, filingsResponse]) => {
            setIsFollowing(companyResponse.data.isFollowing);
            setCompanyData(companyResponse.data.company);
            setFilingsData(filingsResponse.data.filings);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, [ticker, currentUser]);

    const handleFollowClick = () => {
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const url = `${API_URL}/api/users/portfolio/${isFollowing ? 'remove' : 'add'}/main/${ticker}`;
        const headers = { Authorization: `Bearer ${currentUser.token}` };

        axiosInstance.post(url, {}, { headers })
            .then(response => {
                setIsFollowing(response.data.isFollowing);
            })
            .catch(error => {
                console.error('Error updating follow status:', error);
            });
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Filings':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-white rounded-lg shadow-lg">
                        {renderFilingCategories()}
                    </div>
                );
            case 'Transcripts':
                return (
                    <div className="p-4 bg-white rounded-lg shadow-lg">
                        <Transcripts ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            case 'Insider Transactions':
                return (
                    <div className="p-4 bg-white rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Insider Transactions - Grouped by filing</h2>
                        <TransactionsTableTGGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            default:
                return null;
        }
    };

    const renderFilingCategories = () => {
        if (!filingsData || Object.keys(filingsData).length === 0) {
            return ['News', 'Financial', 'Prospectus', 'Proxies', 'Ownership', 'SEC Correspondence'].map(category => (
                <div key={category} className="bg-white p-4 rounded-lg shadow">
                    <h2 className="text-lg font-bold">{category}</h2>
                </div>
            ));
        }

        return (
            <>
                <FilingCategory title="News" filings={filingsData['News'] || []} ticker={ticker} currentUser={currentUser} />
                <FilingCategory title="Financial" filings={filingsData['Financial'] || []} ticker={ticker} currentUser={currentUser} />
                <FilingCategory title="Prospectus" filings={filingsData['Prospectus'] || []} ticker={ticker} currentUser={currentUser}/>
                <FilingCategory title="Proxies" filings={filingsData['Proxies'] || []} ticker={ticker} currentUser={currentUser} />
                <FilingCategoryOwnershipFilings title="Ownership" filings={filingsData['Ownership'] || []} ticker={ticker} currentUser={currentUser} />
                <FilingCategory title="SEC Correspondence" filings={filingsData['SEC Correspondence'] || []} ticker={ticker} currentUser={currentUser}/>
            </>
        );
    };

    if (!companyData) {
        return (
            <div className="bg-gray-100 min-h-screen">
                <NavBarSmall />
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <NavBarSmall />
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-3xl font-bold text-gray-900">
                            {companyData.name}
                            {companyData.tickers && (
                                <span className="text-gray-600 ml-2">
                                    ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})
                                </span>
                            )}
                        </h1>
                        <button
                            className={`px-4 py-2 rounded-full text-sm font-semibold ${
                                isFollowing ? 'bg-gray-200 text-gray-700' : 'bg-blue-600 text-white'
                            } transition duration-300 ease-in-out`}
                            onClick={handleFollowClick}
                        >
                            {isFollowing ? 'Unfollow' : 'Follow'}
                        </button>
                    </div>
                    <p className="text-sm text-gray-600 mb-4">{companyData.businessCity}, {companyData.businessState}</p>
                    <CompanyDescription ticker={ticker}/>
                </div>

                <SimilarCompanies ticker={ticker} />
                <CompanySearchBars ticker={ticker} />
                <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                    <div className="flex border-b border-gray-200">
                        {['Filings', 'Transcripts', 'Insider Transactions'].map((tab) => (
                            <button
                                key={tab}
                                className={`py-4 px-6 font-medium text-sm focus:outline-none ${
                                    activeTab === tab
                                        ? 'border-b-2 border-blue-600 text-blue-600'
                                        : 'text-gray-500 hover:text-gray-700'
                                }`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className="p-0">
                        {renderTabContent()}
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    );
}

export default Company;