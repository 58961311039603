import React, { useState } from 'react';
import { RiMenu3Fill } from 'react-icons/ri';
import SearchBar from "./SearchBar/SearchBar";
import ModeledgeName from "./Modeledge";
import UserMenu from "./UserMenuDropDown";

const NavbarSmall = () => {
    // State to manage menu visibility
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Toggle menu visibility
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // bg-nav-bar-theme
    return (
        <div className="bg-code-near-black text-nav-bar-text-color pt-2 pb-1 px-6 border-b border-gray-500">
            {/* Main navigation container */}
            <div className="flex justify-between items-center">
                {/* Logo and title */}
                <a href="/portfolio" className="flex items-center">
                    <img src="/images/logo.png" alt="PocketBase logo" className="w-6 h-6" />
                    <ModeledgeName />
                    <span className="text-sm ml-2"></span>
                </a>

                {/* Search bar and navigation links for large screens */}
                <div className="hidden lg:flex items-center justify-between flex-grow">
                    <SearchBar />

                    <div className="flex items-center"> {/* Ensure this div uses flex to align items inline */}

                        <UserMenu />
                    </div>
                </div>

                {/* Menu button for small screens */}
                <button
                    type="button"
                    className="rounded-full bg-nav-bar-theme p-2 lg:hidden"
                    title="Menu"
                    onClick={toggleMenu} // Attach the toggle function to button click
                >
                    <RiMenu3Fill />
                </button>
            </div>

            {/* Conditional rendering of menu items for small screens */}
            {isMenuOpen && (
                <div className="hidden lg:flex items-center justify-between flex-grow">
                    <SearchBar />

                    <div className="flex items-center"> {/* Ensure this div uses flex to align items inline */}

                        <UserMenu />
                    </div>
                </div>
            )}

            {isMenuOpen && (
                <div className="flex justify-end items-center lg:hidden">
                    <UserMenu />
                </div>
            )}


            {/* SearchBar for small screens */}
            <div className="lg:hidden mt-2">
                <SearchBar/>
            </div>
        </div>
    );
};

export default NavbarSmall;
