import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import FilingCard from './FilingCards/FilingCard';
import LoadMoreFilingCards from "./LoadMoreFilingsButton";
import axiosInstance from '../api';
import { API_URL } from "../config";

function FilingCategoryOwnershipFilings({ key, filings, title, ticker, currentUser }) {
    const [allFilings, setAllFilings] = useState(filings);
    const navigate = useNavigate();

    const loadMoreFilings = async () => {
        try {
            const newFilings = await fetchMoreFilings();
            setAllFilings([...allFilings, ...newFilings]);
        } catch (error) {
            // Handle errors
        }
    };

    useEffect(() => {
        // Handle the updated filingsData here
    }, [allFilings]);

    const fetchMoreFilings = async () => {
        try {
            const headers = currentUser
                ? { Authorization: `Bearer ${currentUser.token}` }
                : {};
            const response = await axiosInstance.get(`${API_URL}/api/filings/${ticker}?category=${title}&count=20&start=${allFilings.length}`, { headers});
            return response.data; // Assuming response.data contains the filings
        } catch (error) {
            console.error('Error fetching company data:', error);
            throw error; // Rethrow the error to be caught in loadMoreFilings
        }
    };

    useEffect(() => {
        // Update the state when the filings prop changes
        setAllFilings(filings);
    }, [filings]); // Add filings to the dependency array


    return (
        <div className="bg-gray-200 py-2 px-3 rounded">
            <div className={"flex flex-row items-center mb-2"}>
                <h2 className="text-lg font-bold ">{title}</h2>
                <button
                    className={"ml-2 bg-orange-700 text-white border rounded-md text-sm p-1 px-2"}
                    onClick={() => navigate(`/company/insider/${ticker}`)}
                >Transactions
                </button>
            </div>
            {allFilings.map((filing, index) => (
                <FilingCard
                    key={filing.AccessionNumber}
                    filing={filing}
                    isLastItem={index === allFilings.length - 1}
                />
            ))}
            <LoadMoreFilingCards title={title} numItems={allFilings.length} onLoadMore={loadMoreFilings}></LoadMoreFilingCards>
        </div>
    );
}

export default FilingCategoryOwnershipFilings;
