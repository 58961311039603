import { FaTwitter } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-filing-menu-background-light py-8 border-t border-gray-200">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4">Company</h3>
                        <ul>
                            <li><a href="/" className="text-gray-600 hover:text-gray-800">Home</a></li>
                            <li><a href="/about" className="text-gray-600 hover:text-gray-800">About</a></li>
                            <li><a href="/contact" className="text-gray-600 hover:text-gray-800">Contact</a></li>
                        </ul>
                    </div>

                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4">Legal</h3>
                        <ul>
                            <li><a href="/terms" className="text-gray-600 hover:text-gray-800">Terms</a></li>
                            <li><a href="/privacy" className="text-gray-600 hover:text-gray-800">Privacy</a></li>
                        </ul>
                    </div>

                    <div className="w-full md:w-1/3">
                        <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                        <ul className="flex">
                            <a href="https://twitter.com/FwdQuarter"
                               className="text-gray-600 hover:text-gray-800 mr-4"
                               target="_blank"
                               rel="noopener noreferrer">
                                <FaTwitter size={25} />
                            </a>
                        </ul>
                    </div>
                </div>

                <div className="pt-4 mt-8 border-t border-gray-100">
                    <p>&copy; 2024 Modeledge - All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;