// SubscriptionTier.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from "../api";
import {API_URL} from "../config";
import {useAuth} from "../firebase/AuthContext";
import { useNavigate } from 'react-router-dom';
import {Bullet} from "./ui/checkBullet";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


function SubscriptionTier({ tier }) {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleSubscribe = async () => {
        const stripe = await stripePromise;

        if (!currentUser || !currentUser.token) {
            navigate('/login');
            return;
        }

        const headers = currentUser
            ? {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentUser.token}` }
            : {};

        try {
            const response = await axiosInstance.post(
                `${API_URL}/checkout/create-checkout-session`,
                { priceId: tier.stripePriceId },
                { headers }
            );


            console.log("Server response:", response.data);

            if (!response.data || !response.data.sessionId) {
                throw new Error("Invalid response from server: missing session ID");
            }

            const { sessionId } = response.data;

            console.log("Redirecting to checkout with sessionId:", sessionId);

            const result = await stripe.redirectToCheckout({
                sessionId,
            });

            if (result.error) {
                console.error("Stripe redirect error:", result.error);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col justify-between">
            <div>
                <h2 className="text-2xl font-bold mb-4">{tier.name}</h2>
                <p className="text-4xl font-bold mb-6">${tier.price}/month</p>
                <ul className="mb-6">
                    {tier.features.map((feature, index) => (
                        <Bullet key={index} className="mb-2" text={feature} ></Bullet>
                    ))}
                </ul>
                <p className="mb-6">{tier.description}</p>
            </div>
            <button onClick={handleSubscribe} className="bg-neutral-900 hover:bg-neutral-600 text-white font-bold py-2 px-4 rounded transition duration-300">
                Subscribe to {tier.name}
            </button>
        </div>
    );
}

export default SubscriptionTier;