import React from "react";
import { format, isToday, isYesterday } from 'date-fns';
import * as stringUtils from '../../utils/stringUtils';


function FilingCardCompanySearch({ filing = {}, isLastItem = false, allSmall = false }) {
    const getRelativeDate = (date) => {
        if (isToday(date)) return "Today";
        if (isYesterday(date)) return "Yesterday";
        return null;
    };

    const formatFilingDate = (dateString) => {
        return format(new Date(dateString), 'MM/dd/yy');
    };

    const read = filing.read ?? false;
    const filingDate = new Date(filing.filing_date);
    const relativeDate = getRelativeDate(filingDate);
    const processedTitle = filing.filing_type === "8-K"
        ? stringUtils.shortenStringByRemovingAnnounces(filing.title)
        : filing.title;

    return (
        <div
            className={`
                flex items-center w-full px-2 py-1.5
                ${isLastItem ? 'border-b' : ''} border-t border-gray-200
                ${read ? 'bg-gray-50' : 'bg-white'}
                hover:bg-blue-50 transition-colors duration-150 ease-in-out
            `}
        >
            {allSmall ?
                <div className="w-10 shrink-0 font-medium text-xs text-gray-600 truncate">
                    {filing.filing_type}
                </div>
                :
                <div className="w-16 shrink-0 font-medium text-xs text-gray-600 truncate">
                    {filing.filing_type}
                </div>
            }

            <div className="flex-grow px-2 min-w-0">
                <p className="text-xs text-gray-800 truncate" title={filing.title}>
                    {processedTitle}
                </p>
            </div>
            <div className="flex flex-col items-end shrink-0">
                <span className="text-xs text-gray-500 whitespace-nowrap">
                    {formatFilingDate(filing.filing_date)}
                </span>
                {relativeDate && (
                    <span className={`
                        text-[10px] mt-0.5 py-0.5 px-1.5 rounded-full whitespace-nowrap
                        ${relativeDate === "Today"
                        ? "bg-emerald-100 text-emerald-700"
                        : "bg-amber-100 text-amber-700"}
                    `}>
                        {relativeDate}
                    </span>
                )}
            </div>
        </div>
    );
}

export default FilingCardCompanySearch;