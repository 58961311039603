// CompanyInsider.js
import { API_URL } from '../config.js';
import NavBarSmall from "./NavBarSmall";
import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import {useNavigate, useParams} from 'react-router-dom';
import FooterSmall from "./FooterSmall";
import {useAuth} from "../firebase/AuthContext";
import SimilarCompanies from "./SimilarCompanies/SimilarCompanies";
import TransactionsTableTGGroup from "./InsiderTransactions/InsidersTGGroup";
import CompanySearchBars from "./CompanySearchBars";
import {arrayToStringWithCommasAndLengthLimitIntelligent} from "../values";

function CompanyInsiderTG() {
    const [companyData, setCompanyData] = useState(null);
    const { ticker } = useParams();
    const [isFollowing, setIsFollowing] = useState(false);
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleFollowClick = () => {
        if (currentUser && !isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/add/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else if (currentUser && isFollowing) {
            const headers = currentUser
                ? {Authorization: `Bearer ${currentUser.token}`}
                : {};
            const url = `${API_URL}/api/users/portfolio/remove/main/${ticker}`;
            axiosInstance.post(url, {}, {headers: headers})  // Modified line
                .then(response => {
                    setIsFollowing(response.data.isFollowing);
                })
                .catch(error => {
                    console.error('Error updating follow status:', error);
                });
        } else {
            navigate('/login')
        }
    };

    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};
        axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers })
            .then(response => {
                setIsFollowing(response.data.isFollowing)
                setCompanyData(response.data.company); // Update this line
            })
            .catch(error => {
                console.error('Error fetching company data:', error);
            });
    }, [ticker, currentUser]);



    useEffect(() => {
        //
    }, [isFollowing]);

    if (!companyData) {
        return (
            <>
                <div className="bg-code-light-black bg-center min-h-screen">
                <NavBarSmall />
                <div className="bg-filing-menu-background bg-center h-screen">

                </div>
            </div>
            </>)
    }



    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="lg:w-4/5 md:w-4/5 w-full mx-auto">
                <div className="md:px-0 lg:px-4 py-1 sm:p-0">
                    <div>
                        <div className="container mx-auto md:py-4 py-1 lg:mt-2 ms:mt-1 px-1 md:px-0">
                            <div>
                                {
                                    companyData.tickers ?
                                        <h1 className="text-3xl text-filing-menu-text-color font-bold inline">{companyData.name} ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})</h1>
                                        :
                                        <h1 className="text-3xl text-filing-menu-text-color font-bold inline">{companyData.name}</h1>
                                }
                                <button
                                    className={`ml-4 p-1 rounded text-xs font-semibold ${isFollowing ? 'bg-company-button text-bg-gray-100' : 'bg-gray-500 text-white'} text-white`}
                                    onClick={handleFollowClick}
                                >
                                    {isFollowing ? 'Unfollow' : 'Follow'}
                                </button>
                              </div>

                            <p className="text-sm text-gray-400">{companyData.businessCity}, {companyData.businessState}</p>
                        </div>
                    </div>
                    <SimilarCompanies ticker={ticker} />
                    <CompanySearchBars ticker={ticker} />
                </div>
                <div className="container mx-auto md:p-4 lg:p-4 lg:pt-1 sm:p-0 ">
                    <div className="bg-gray-200 md:p-2 p-0">
                        <h2 className="text-lg font-bold lg:px-4 pt-1 px-2">Insider Transactions - Grouped by filing</h2>
                        <TransactionsTableTGGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    )
}

export default CompanyInsiderTG;
