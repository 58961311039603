import React from "react";

export const LLMWarning = () =>  {
    return (
        <div className="mt-2 text-xs text-gray-500 italic">
            Note: While we strive for accuracy, this response is generated by an AI language model and may be inaccurate. Please verify important information. No information should be used exclusively to make investment decisions.
        </div>
    );
};

export const LLMPhilosophy = () =>  {
    return (
        <div className="mb-2 text-xs text-gray-500 italic">
            Our queries take time to run (15s to 1 minute). This is not a chat interface. This query service is for recent data only, for historical queries, we recommend standard text search.
        </div>
    );
};

export const CompanyQueryTips = () => {
    return (
        <div className="mb-2 text-xs text-gray-500 italic">
            Tips: To include specific tables in the data, use table search key words in brackets, like [revenue], [income statement] or [debt]
        </div>
    );
};
