import React from "react";

const Logo = ({ menuOpened = true }) => {
    return (
        <a href="/portfolio" className="flex items-center">
            <img src="/images/logo_modeledge.png" alt="Modeledge logo" className="w-10 h-10" />
            {menuOpened ?
                <span className="hidden md:inline text-lg font-medium ml-2">Model<strong>Edge</strong></span>
                :
                <span className="hidden md:inline text-lg font-medium text-filing-menu-background ml-2">Model<strong>Edge</strong></span>
            }
        </a>
    );
}

export default Logo;