import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import CompanyTickerBubbleField from "./CompanyTickerBubbleField";

const SimilarCompanies = ({ ticker }) => {
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setCompanies([]);
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`${API_URL}/api/company/facts/similar/${ticker}`);
                setCompanies(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [ticker]);

    const handleGroupButtonClick = (ticker) => {
        navigate(`/industry/filings/company/${ticker}`);
    };

    if (!companies || !Array.isArray(companies)) { // Render nothing if companies is not an array
        return null;
    }

    return (
        <div className="flex flex-row flex-wrap px-1 md:px-0">
            <CompanyTickerBubbleField companies={companies} />
            {
                companies && companies.length > 0 &&

            <div className="relative mr-1 group">
                <button
                    className="bg-code-gray-text hover:bg-green-600 text-filing-menu-text-color font-bold py-1 px-2 rounded md:text-sm text-xs items-center md:mt-0 mt-1 border border-gray-400"
                    onClick={() => handleGroupButtonClick(ticker)}
                >
                    Open Group Filings
                </button>
                <span className="absolute hidden group-hover:block bg-gray-700 text-filing-menu-text-color text-xs rounded py-1 px-2 bottom-full whitespace-nowrap mb-1">
                    Open recent filings for industry group
                </span>
            </div>
            }
        </div>
    );
};

export default SimilarCompanies;