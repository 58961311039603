import React from 'react';
// Import the SVG image
import DownArrowIcon from './down-arrow.svg'; // Adjust the path as needed

function LoadMoreFilingCards({ title = {}, numItems = 10, onLoadMore }) {
    // Tailwind CSS classes for styling
    //  border border-b border-t-0 border-gray-300
    let cardClasses = `bg-gray-100 hover:bg-gray-200 px-4 py-1`;
    //let dateLabelClass = 'text-xs font-medium px-2 py-1 rounded-full';

    return (
        <div className={cardClasses} onClick={onLoadMore}>
            <div className="text-sm hover:text-blue-500">
                <div className="flex items-center m-1">
                    <div className="flex items-center justify-center mx-auto">
                        <div className="flex items-center justify-center mx-auto">
                            <img src={DownArrowIcon} alt="Load More" className="w-2 h-2" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadMoreFilingCards;
