import React from 'react';
import NavBarSmall from "./NavBarSmall";
import FooterSmall from "./FooterSmall";
import RecentFilingsEmptyPortfolioWithCount from "./RecentFilings";
import {useParams} from "react-router-dom";
// import ToggleDataTypes from "./Toggles/ToggleFilingCategoryTypes";
// import CompanySlider from "./Toggles/CompanyCountSlider";

function RecentFilingsFeed() {
    const { n } = useParams();
/*    const [selectedDataType, setSelectedDataType] = useState('News');
    const [companyCount, setCompanyCount] = useState(10);

    const handleDataTypeSelect = (type) => {
        setSelectedDataType(type);
    };

    const handleCompanyCountChange = (count) => {
        setCompanyCount(count);
    };

    const fetchData = () => {
        // Fetch data based on `selectedDataType` and `companyCount`
        // Example: fetchDataFromAPI(selectedDataType, companyCount);
    };*/

    console.log("n=" + n)

    return (
        <div className="bg-gray-300 bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="flex flex-col bg-gray-300 min-h-screen">
                <div className="text-center bg-cover p-2">
                    <div className="w-full bg-company-data-background pb-8">
                        {/*}
                        <div className="mx-auto max-w-fit shadow-sm rounded-sm border-accent-one">
                            <div className="flex flex-col md:flex-row justify-between items-center">
                                <div className="text-xl font-bold">Recent Filings</div>
                                <ToggleDataTypes onSelect={handleDataTypeSelect} />
                                <CompanySlider onChange={handleCompanyCountChange} />
                                <button
                                    className="bg-nav-bar-theme hover:bg-green-600 text-white text-sm font-bold py-2 px-4 ml-2 rounded"
                                    onClick={fetchData}
                                >
                                    Fetch Data
                                </button>
                            </div>
                        </div>
                        */}


                        <div className="md:w-3/5 w-11/12 mx-auto pt-4">
                            <RecentFilingsEmptyPortfolioWithCount portfolioName={"empty"} n={n} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    );
}

export default RecentFilingsFeed;