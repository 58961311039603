import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import TableSearchWithModal from "./TableSearchInput/TableSearchWithModal";
import FilingCardCompanySearch from './FilingCards/FilingCardCompanySearch';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/AuthContext";
import CompanyButton from '../Components/CompanyButton';
import {API_URL} from "../config";
import Logo from '../Components/Logo';

function CompanyTableSearchFilings() {
    const { ticker, table_id } = useParams();
    const [filings, setFilings] = useState(null); 
    const [selectedTable, setSelectedTable] = useState(null);
    const [company, setCompany] = useState(null);
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};

        axiosInstance.get(`${API_URL}/tables/series/filings?id=${table_id}`, { headers })
            .then((response) => {
                const responseData = response.data;
                if (responseData && responseData.filings) {
                    setFilings(responseData.filings);
                    setCompany(responseData.company);
                    // Find and set the selected table
                    let foundTable;
                    responseData.filings.forEach(filing => {
                        const table = filing.tables.find(table => table.id === table_id);
                        if (table) foundTable = table;
                    });
                    if (foundTable) setSelectedTable(foundTable);
                } else {
                    console.error("Unexpected response structure:", responseData);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [table_id, currentUser]);

    const handleTableClick = (table) => {
        setSelectedTable(table);
        navigate(`/tables/${ticker}/${table.id}`);
    };

    const openFiling = () => {
        window.open(`/company/${ticker}/filing/${selectedTable.filing.filingType}/${selectedTable.filing.accessionNumber}#table-${selectedTable.htmlTableIndex}`, "_blank");
    }

    const formatFilingDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().substr(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        return `${month}/${day}/${year}`;
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex flex-row h-screen">
                <div className={`overflow-hidden transition-width duration-300 ease-in-out overflow-y-auto ${true ? 'w-72' : 'w-3'} `}>
                    {/* Sidebar components */}
                    <div className="p-2 ml-2">
                        <Logo/>
                    </div>
                    <CompanyButton company={company} ticker={ticker} />
                    <TableSearchWithModal ticker={ticker} />

                    {filings ? filings.map((filing) => (
                        filing.tables.map((table, index) => (
                            <div
                                key={table.id}
                                className="flex flex-col border border-gray-300 rounded-xs mx-2 my-1 px-0 cursor-pointer text-black w-9/10 max-w-4xl bg-gray-50"
                                onClick={() => handleTableClick(table)}
                            >
                                <FilingCardCompanySearch filing={table.filing}></FilingCardCompanySearch>
                            </div>
                        ))
                    )) : <p>Loading filings...</p>}
                </div>
                <div className="flex-1 h-full bg-white text-black p-3">
                    {selectedTable?.html ? (
                        <div>
                            <h4 className="text-2xl font-bold">From {selectedTable.filing.filingType} ({formatFilingDate(selectedTable.filing.filingDate)}) </h4>
                            <div className="border border-gray-400 rounded p-3" dangerouslySetInnerHTML={{ __html: selectedTable.html }} />
                            <button onClick={openFiling} className="bg-gray-400 hover:bg-gray-800 text-white font-bold py-2 px-4 mt-2 rounded">
                                Open Filing
                            </button>
                        </div>
                    ) : <p>Select a table to view details.</p>}
                </div>
            </div>
        </div>
    );
}

export default CompanyTableSearchFilings;