import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  onIdTokenChanged,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile
} from 'firebase/auth';
import { auth } from './firebaseConfig.js';
import Cookies from 'js-cookie';
import axios from "axios";
import { API_URL } from '../config';

const AuthContext = createContext();

axios.defaults.withCredentials = true;

export function useAuth() {
  return useContext(AuthContext);
}

const handleUser = async (user, forceRefresh = false) => {
  if (user) {
    let token = await user.getIdToken(forceRefresh);
    await axios.post(`${API_URL}/api/user/set-cookie`, { idToken: token });
    return {
      id: user.uid,
      email: user.email,
      name: user.displayName,
      token
    };
  } else {
    Cookies.remove('auth');
    return null;
  }
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState(null);


  const clearError = () => setError(null);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/checkAuth`, { withCredentials: true });
        if (response.data.user) {
          setCurrentUser(response.data.user);
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('No user authenticated');
        }
        setCurrentUser(null);
      }

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const userData = await handleUser(user);
            setCurrentUser(userData);
          } catch (error) {
            console.error('Error handling user:', error);
            setCurrentUser(null);
          }
        } else {
          setCurrentUser(null);
        }
        setLoading(false);
      });

      return unsubscribe;
    };




    initAuth();
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      setFirebaseUser(user);
      if (user) {
        try {
          const userData = await handleUser(user);
          setCurrentUser(userData);
        } catch (error) {
          console.error('Error handling user:', error);
          setCurrentUser(null);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const refreshToken = async () => {
    if (firebaseUser) {
      try {
        const token = await firebaseUser.getIdToken(true); // Force refresh
        setCurrentUser((prev) => ({ ...prev, token }));
        // Optionally, update the backend session
        await axios.post(`${API_URL}/api/user/set-cookie`, { idToken: token });
        return token;
      } catch (error) {
        setError(error);
        throw error;
      }
    } else {
      throw new Error('No user is currently logged in');
    }
  };


  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setError(err);
    }
  };

  const signInWithTwitter = async () => {
    const provider = new TwitterAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      setError(err);
    }
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error);
      throw error; // rethrow the error to catch it in the modal component
    }
  };

  const signUp = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error);
      throw error; // rethrow the error to catch it in the modal component
    }
  };

  const signOutUser = () => {
    signOut(auth)
        .then(() => {
          // Redirect to a specific page after successful sign-out
          window.location.href = '/landing';
        })
        .catch(setError);
  };

  const resetPassword = (email) => sendPasswordResetEmail(auth, email).catch(setError);
  const updateProfileUser = (updates) => currentUser && updateProfile(currentUser, updates).catch(setError);

  const value = {
    currentUser,
    error,
    clearError,
    signInWithGoogle,
    signInWithTwitter,
    signIn,
    signUp,
    signOutUser,
    resetPassword,
    updateProfileUser,
    refreshToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}