
import { Link } from 'react-router-dom';
function CompanyColumn({ companies }) {
    return (
        <div className="shadow rounded-sm border-x border-b border-gray-400">
            {companies.map((company) => (
                <Link to={`/company/${company.ticker ? company.ticker[0] : company.cik}`} className="rounded-sm w-full flex ">
                    <div className="bg-filing-background hover:bg-gray-200 border-t border-gray-400 md:py-1 py-2 m-0 w-full">
                        <div className="flex flex-row justify-between items-center w-full px-2 rounded-sm">
                            <div className="flex items-center gap-2 text-xs pl-2 pr-3 w-full">
                                <div className="font-roboto flex flex-col items-start justify-start w-full mr-2 whitespace-nowrap">
                                    <h3 className=" overflow-hidden overflow-ellipsis whitespace-nowrap">
                                        {company.name} {company.ticker ? '(' + company.ticker[0] + ')' : ''}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default CompanyColumn;