import React, { useState, useEffect } from 'react';
import axiosInstance from '../../api';
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatNumber } from "../../values";
import {InsiderInfoCell, InsiderDateCell, ValueCell} from "./Insiders/TableCells";

const TransactionsTable = ({ ticker, currentUser }) => {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    //const { ticker } = useParams();
    //const { currentUser } = useAuth();
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchTransactions = async () => {
            const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};
            const url = `${API_URL}/api/company/insiders/${ticker}`;

            try {
                const response = await axiosInstance.get(url, { headers });
                setTransactions(response.data.transactions);
            } catch (error) {
                setError(error.toString());
            } finally {
                setIsLoading(false);
            }
        };

        fetchTransactions();
    }, [ticker, currentUser]);

    const handleRowClick = (fid) => {
        navigate(`/company/${ticker}/filing/4/${fid}`); // Navigate to the specified URL
    };

    if (isLoading) return <div className={"bg-cover bg-center min-h-screen"}></div>;
    if (error) return <div>Error: {error}</div>;

    return (
            <div className="w-4/5 mx-auto">
                <div className="bg-cover bg-center min-h-screen">
                <div className="mx-auto p-4 pt-1">
                    <table className="w-full text-xs transition-opacity duration-300 overflow-anchor-none bg-cover">
                        <thead>
                        <tr className="text-left bg-gray-100" >
                            <th>Date</th>
                            <th>Owner</th>
                            <th>Type <span className="font-normal text-gray-400">(filtered)</span></th>
                            <th>Security</th>
                            <th className="text-right">Price</th>
                            <th className="text-right">Units</th>
                            <th className="text-right">Value</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {transactions.map(transaction => (
                            transaction.insider_transactions.map(insiderTransaction => (
                                <tr key={insiderTransaction.insider_transaction.id}
                                    className={`${insiderTransaction.insider_transaction.AcquiredDisposedCode === 'D' ? 'bg-red-200 hover:bg-red-300' : 'bg-green-200 hover:bg-green-300'} border-t border-gray-200 hover:shadow-md`}
                                    onClick={() => handleRowClick(insiderTransaction.insider_transaction.FID)}
                                >

                                    <InsiderDateCell date={insiderTransaction.insider_transaction.TransactionDate} ></InsiderDateCell>
                                    <InsiderInfoCell ownerCIK={insiderTransaction.insider_transaction.OwnerCIK} ownerName={insiderTransaction.insider_transaction.OwnerName} ownerRelationship={insiderTransaction.insider_transaction.OwnerRelationship} />

                                    <td>{insiderTransaction.insider_transaction.Type}</td>
                                    <td className="max-w-sm overflow-hidden text-ellipsis whitespace-nowrap">{insiderTransaction.insider_transaction.SecurityTitle}</td>
                                    <td className="text-right">
                                        {typeof insiderTransaction.insider_transaction.PricePerShare === 'number' ? formatCurrency(insiderTransaction.insider_transaction.PricePerShare, 2, "$") : ''}
                                    </td>
                                    <td className="text-right">{formatNumber(insiderTransaction.insider_transaction.Shares, 0)}</td>

                                    <ValueCell value={insiderTransaction.insider_transaction.NotionalValue}></ValueCell>
                                    <td className="w-9 p-0 text-center">
                                        <a href={`/filing/${insiderTransaction.insider_transaction.FID}/1?cik=${insiderTransaction.insider_transaction.IssuerCIK}`}>
                                            <span className="icon-doc-text"></span>
                                        </a>
                                    </td>
                                    <td className="w-9 p-0 text-center">
                                        <button type="button" className="btn-link">
                                            <span className="icon-left-open"></span>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ))}
                        </tbody>
                    </table>
                </div>
                </div>
        </div>
    );
}

export default TransactionsTable;