import React, { useState, useEffect } from "react";
import axiosInstance from '../../api';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../config";
import FilingCardCompanySearch from "../FilingCards/FilingCardCompanySearch";

function TableSearchDivV2({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [tables, setTables] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

    const handleTableClick = (id) => {
        navigate(`/tables/${ticker}/${id}`);
    };

    const handleTableFilingClick = (filing) => {
        navigate(`/company/${ticker}/filing/${filing.filing_type}/${filing.accession_number}`);
    };

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (searchTerm) {
                setError(null);
                axiosInstance
                    .get(`${API_URL}/tables/search/${ticker}?q=${searchTerm}`)
                    .then((response) => {
                        setTables(response.data);
                    })
                    .catch((error) => {
                        console.error("Error fetching suggestions:", error);
                        setError(error);
                    });
            } else {
                setTables([]);
            }
        }, 225);

        return () => clearTimeout(debounceTimeout); // Clear timeout on unmount or before setting a new one
    }, [searchTerm, ticker]);

    useEffect(() => {
        if (searchTerm === "") {
            setTables([])
        }
    }, [searchTerm]);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            setSelectedSuggestionIndex((prevIndex) =>
                Math.min(prevIndex + 1, tables.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            setSelectedSuggestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (e.key === "Enter") {
            if (selectedSuggestionIndex >= 0 && tables[selectedSuggestionIndex]?.id) {
                const selectedTable = tables[selectedSuggestionIndex];
                handleTableClick(selectedTable.id);
            } else if (selectedSuggestionIndex === -1) {
                // do nothing
            }
        }
    };

    const clearSearch = () => {
        setSearchTerm("");
    };

    if (error) {
        return <div>Error fetching data</div>;
    }

    return (
        <div>
            <div className="flex items-center bg-filing-menu-background rounded text-sm border border-gray-400 py-2 h-10 relative max-w-4xl md:min-w-[500px] min-w-full mx-auto" >
                <input
                    type="text"
                    placeholder="Table search: revenues, customers, cash flow, debt, net income..."
                    className="border-none bg-gray-100 text-gray-600 text-sm p-1 px-2 mx-1 outline-none rounded-sm w-full"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    className="bg-company-button hover:bg-code-light-black text-white text-xs font-bold py-1 px-2 m-2 rounded"
                    onClick={clearSearch}
                >
                    Clear
                </button>
            </div>
            <div className="">
                {searchTerm && tables && tables.length > 0 ? (
                    tables.map((table, index) => (
                        <div
                            className="flex flex-col border border-gray-300 rounded px-0 cursor-pointer text-black w-full md:max-w-4xl mx-auto bg-gray-50 my-2"
                        >
                            <div
                                key={table.filing.accession_number}
                                onClick={() => handleTableFilingClick(table.filing)}
                            >
                                <FilingCardCompanySearch filing={table.filing} exhibit={table.exhibit}></FilingCardCompanySearch>
                            </div>
                            <div key={table.id}
                                 onClick={() => handleTableClick(table.id)}>
                                <div className="flex justify-center md:px-2" dangerouslySetInnerHTML={{ __html: table.html }} />
                            </div>
                        </div>

                    ))) : <></>
                }
            </div>
        </div>
    );
}

export default TableSearchDivV2;
