import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import axiosInstance from '../api';
import {useParams, useLocation, useNavigate} from "react-router-dom";
import { useAuth } from "../firebase/AuthContext";
import { API_URL } from "../config";
import Logo from "./Logo";
import SECFilingIFrame from "./SECFilingIFrame";
import debounce from 'lodash.debounce';
import ArrowTableSearch from "./Arrow/ArrowTableSearchV2";
import TextSearchInput from "./TextSearchInput/TextSearchInputV4";
import CompanyButton from "./CompanyButton/CompanyButtonV4";
import FilingList from "./FilingCardsColumn/FilingsCardColumnDocumentSearch";
import {SubscriptionModal} from "./SubscriptionModal/SubscriptionModal";
import { useSubscriptionModal} from "./SubscriptionModal/useSubscriptionModal";
import TranscriptIFrame from "./Transcript/TranscriptIFrameV3";

function CompanyDocumentSearch() {
    const { ticker } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialQuery = searchParams.get('query') || ''; // Capture initial query from URL or default to an empty string
    const [query, setQuery] = useState(initialQuery);
    const [filingData, setFilingData] = useState({ news: [], financial: [], proxies: [], ownership: [], prospectus: [], sec_correspondence: [] });
    const { currentUser } = useAuth();
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768);
    const [selectedFilingTextChunks, setSelectedFilingTextChunks] = useState([]);
    const [selectedFilingDetails, setSelectedFilingDetails] = useState(null);
    const navigate = useNavigate();
    const asideRef = useRef(null);
    const [selectedChunk, setSelectedChunk] = useState(null);
    const memoizedCurrentUser = useMemo(() => currentUser, [currentUser]);
    const [company, setCompany] = useState(null);
    const searchInputRef = useRef(null);
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();


    useEffect(() => {
        if (asideRef.current) {
            asideRef.current.scrollTop = 0;
        }
    }, [selectedFilingTextChunks]);

    useEffect(() => {
        console.log('Component mounted');
        return () => console.log('Component unmounted');
    }, []);

    const [cache, setCache] = useState({});
    const [debouncedQuery, setDebouncedQuery] = useState(initialQuery);

    // Create the debounced function outside of useCallback
    const debouncedSetQueryFn = debounce((value) => setDebouncedQuery(value), 500);

    // Use useCallback with the debounced function
    const debouncedSetQuery = useCallback((value) => {
        debouncedSetQueryFn(value);
    }, [debouncedSetQueryFn]);

    useEffect(() => {
        debouncedSetQuery(query);
    }, [query, debouncedSetQuery]);

    useEffect(() => {
        console.log('Effect triggered with query:', debouncedQuery);
        if (memoizedCurrentUser && debouncedQuery) {
            const cacheKey = `${ticker}-${debouncedQuery}`;
            if (cache[cacheKey]) {
                setFilingData(cache[cacheKey]);
                return;
            }

            const headers = { Authorization: `Bearer ${memoizedCurrentUser.token}` };

            axiosInstance.get(`${API_URL}/api/company/documents/search/${ticker}?q=${debouncedQuery}`, { headers })
                .then((response) => {
                    const dataCompany = response.data.company
                    const responseData = response.data.results;
                    if (responseData && typeof responseData === 'object') {
                        setFilingData(responseData);
                        setCache(prevCache => ({...prevCache, [cacheKey]: responseData}));
                    } else {
                        console.error('Unexpected response data format:', responseData);
                    }

                    if (dataCompany) {
                        setCompany(dataCompany)
                    }

                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            handleSubscriptionModal();
                        } else if (error.response.status === 401) {
                            navigate('/login', { state: { redirectPath: `/Company/${ticker}` } });
                        }
                    }
                    console.error("Error fetching data:", error);
                });
        } else {
            console.log("No user logged in")
            navigate('/login', { state: { redirectPath: `/Company/${ticker}` } });
        }
    }, [debouncedQuery, ticker, memoizedCurrentUser, cache, navigate, handleSubscriptionModal]);

    const handleFilingClick = async (filing) => {
        setSelectedFilingTextChunks([]);
        setSelectedFilingDetails(null);

        // Assuming filing.results contains the text chunks
        const textChunks = filing.results || [];
        setSelectedFilingTextChunks(textChunks);

        try {

            if (filing.category === "Transcripts") {

                const iframeURL = `/api/transcripts/${filing.document_id}`
                let initialHighlightSentence = null;
                let initialHighlightIDs = null;

                // Automatically select the first text chunk for highlighting
                if (textChunks.length > 0) {
                    const firstChunk = textChunks[0];
                    setSelectedChunk(firstChunk);
                    initialHighlightSentence = removeHtmlAndEllipses(firstChunk.text_content);
                    initialHighlightIDs = firstChunk.text_content_id;
                    console.log("Have text chunks")
                    console.log(textChunks)
                    console.log("Highlighing: " + initialHighlightSentence)
                }

                setSelectedFilingDetails({
                    url: iframeURL,
                    cik: filing.cik,
                    fid: filing.fid,
                    filing_type: "Transcript",
                    document_id: filing.document_id,
                    seq: filing.seq,
                    highlightSentence: initialHighlightSentence, // Set from the first chunk
                    highlightIDs: initialHighlightIDs // Set from the first chunk
                });
                return
            }

            const url = `/api/filing/${filing.fid}`

            const response = await axiosInstance.get(`${url}`, {
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
            });
            const data = response.data;

            let exhibitURL = data.sec_filing.PrimaryDocumentHref;

            if (filing.seq > 0) {
                const exhibit = data.exhibits.find(ex => ex.sequence === filing.seq);
                if (exhibit && exhibit.url) {
                    exhibitURL = exhibit.url;
                }
            }

            let initialHighlightSentence = null;
            let initialHighlightIDs = null;

            // Automatically select the first text chunk for highlighting
            if (textChunks.length > 0) {
                const firstChunk = textChunks[0];
                setSelectedChunk(firstChunk);
                initialHighlightSentence = removeHtmlAndEllipses(firstChunk.text_content);
                initialHighlightIDs = firstChunk.text_content_id;
            }

            // Update the selected filing details state with the new data and the first chunk's highlight details
            setSelectedFilingDetails({
                url: exhibitURL,
                cik: data.company.cik,
                fid: data.sec_filing.FID,
                eid: Number(String(filing.fid) + String(filing.seq)),
                filing_type: data.sec_filing.FilingType,
                document_id: filing.document_id,
                seq: filing.seq,
                highlightSentence: initialHighlightSentence, // Set from the first chunk
                highlightIDs: initialHighlightIDs // Set from the first chunk
            });

        } catch (error) {
            console.error("Error fetching filing details:", error);
        }
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    function removeHtmlAndEllipses(text) {
        let cleanedText = text.replace(/<[^>]*>/g, '').trim();
        if (cleanedText.startsWith('…')) {
            cleanedText = cleanedText.substring(1);
        }
        if (cleanedText.endsWith('…')) {
            cleanedText = cleanedText.substring(0, cleanedText.length - 1);
        }
        return cleanedText.trim();
    }

    const handleTextChunkClick = (chunk) => {
        setSelectedChunk(chunk);
        if (selectedFilingDetails) {
            selectedFilingDetails.highlightSentence =  removeHtmlAndEllipses(chunk.text_content);
            selectedFilingDetails.highlightIDs = chunk.text_content_id
            setSelectedFilingDetails({...selectedFilingDetails});
        }
    };

    const handleSearch = () => {
        setSelectedFilingDetails(null);
        setSelectedChunk(null);
        setSelectedFilingTextChunks([])
        navigate(`?query=${query}`);
    };


    useEffect(() => {
        return () => {
            debouncedSetQueryFn.cancel();
        };
    }, [debouncedSetQueryFn]);

    return (
        <div className="flex flex-col min-h-screen bg-filing-menu-background pl-2">
            <div className="flex flex-row flex-grow">
                <div className={`transition-all duration-300 ease-in-out ${menuVisible ? 'w-80' : 'w-7'} overflow-y-auto overflow-x-hidden h-screen `}>
                    <ArrowTableSearch onClick={toggleMenu} />
                    <div className="flex justify-center items-center text-black my-4">
                        <Logo menuOpened={menuVisible} className="mr-6 ml-2" />
                    </div>
                    <div className={"rounded-sm mb-2 px-1"}>
                        <CompanyButton  company={company} ticker={ticker}/>
                    </div>
                    <div className="p-4">
                        <div className="w-full rounded-sm text-gray-800 relative overflow-hidden">
                            <div className="relative mt-1">
                                <TextSearchInput
                                    query={query}
                                    setQuery={setQuery}
                                    handleSearch={handleSearch}
                                    searchInputRef={searchInputRef}
                                    placeholder={"Search documents"}
                                />
                            </div>
                        </div>
                        {Object.keys(filingData).length > 0 ? (
                            Object.keys(filingData).filter(document_category => filingData[document_category].length > 0).map((category) => (
                                <div key={category} className="">
                                    <div className="w-full flex justify-between my-2">
                                        <div>
                                            <h2 className="text-gray-950 font-semibold">{category}</h2>
                                        </div>
                                        <div>
                                            <h2 className="text-gray-500 pt-1 text-xs">{filingData[category].length} results</h2>
                                        </div>
                                    </div>
                                    <FilingList filingData={filingData} category={category} handleFilingClick={handleFilingClick} />
                                    {/*<div className="bg-white shadow-sm rounded-md overflow-hidden">*/}
                                    {/*    {filingData[category].map((filing, index) => (*/}
                                    {/*        <div*/}
                                    {/*            key={filing.eid + "-" + index}*/}
                                    {/*            onClick={() => handleFilingClick(filing)}*/}
                                    {/*            className="flex flex-col bg-gray-600 hover:bg-gray-500 px-0 cursor-pointer rounded-sm"*/}
                                    {/*        >*/}
                                    {/*            <FilingCardCompanySearch*/}
                                    {/*                filing={filing}*/}
                                    {/*                isLastItem={index === filingData[category].length - 1}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    ))}*/}
                                    {/*</div>*/}
                                </div>
                            ))
                        ) : (
                            <div className="mt-8 text-center bg-white rounded-md shadow-sm p-8 mx-4">
                                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                </svg>
                                <h3 className="mt-4 text-lg font-medium text-gray-900">No Matching Documents Found</h3>
                                <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
                                    Your search did not return any results. Consider refining your query or expanding your search parameters to find relevant documents.
                                </p>
                                <div className="mt-6">
                                    <button
                                        onClick={() => {
                                            setQuery('');
                                            if (searchInputRef.current) {
                                                searchInputRef.current.focus();
                                            }
                                        }}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-grey-900"
                                    >
                                        Clear Search
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <aside ref={asideRef} className="md:w-1/5 w-1/6 overflow-y-auto bg-gray-100 h-screen shadow-md">
                    {selectedFilingDetails ? (
                        <div className="md:p-4 p-1">

                            {selectedFilingDetails.filing_type === 'Transcript' ? (
                                    <button
                                        className="w-full text-sm font-medium py-2 px-4 bg-neutral-600 text-white rounded-md hover:bg-neutral-700 transition duration-200 ease-in-out flex items-center justify-center mb-2"
                                        onClick={() => window.open(`/company/${ticker}/transcript/${selectedFilingDetails.document_id}`, '_blank')}
                                    >
                                        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                                        </svg>
                                        <span className="sm:hidden">Open</span>
                                        <span className="hidden sm:inline">Open Transcript</span>
                                    </button>
                            ) :

                            <button
                                className="w-full text-sm font-medium py-2 px-4 bg-neutral-600 text-white rounded-md hover:bg-neutral-700 transition duration-200 ease-in-out flex items-center justify-center"
                                onClick={() => window.open(`/company/${ticker}/filing/${selectedFilingDetails.filing_type}/${selectedFilingDetails.fid}/exhibit/${selectedFilingDetails.seq}`, '_blank')}
                            >
                                <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                                <span className="sm:hidden">Open</span>
                                <span className="hidden sm:inline">Open Filing</span>
                            </button>
                            }

                            <div className="mt-5">
                                <h3 className="text-lg font-semibold mb-3">Search Results</h3>
                                {selectedFilingTextChunks.map((chunk, index) => (
                                    <div
                                        key={index}
                                        className={`mb-1 md:p-3 p-1 bg-white border rounded-md cursor-pointer transition duration-150 ease-in-out ${
                                            selectedChunk === chunk
                                                ? 'border-gray-500 bg-blue-50 shadow-md'
                                                : 'border-gray-200 hover:bg-gray-50'
                                        }`}
                                        onClick={() => handleTextChunkClick(chunk)}
                                    >
                                        <p className="text-xs text-gray-700 line-clamp-3" dangerouslySetInnerHTML={{ __html: "..." + chunk.text_content + "..." }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : selectedFilingTextChunks.length === 0 ? (
                        <div className="h-full flex flex-col">
                            <div className="p-6 mt-4">
                                <div className="text-center bg-white rounded-md shadow-sm p-6">
                                    <svg className="mx-auto h-10 w-10 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path vectorEffect="non-scaling-stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                                    </svg>
                                    <h3 className="mt-2 text-sm font-medium text-gray-900">No filing selected</h3>
                                    <p className="mt-1 text-sm text-gray-500">Select a filing to view search results.</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </aside>

                {/*<div className="md:w-1/5 w-1/6 overflow-y-auto bg-gray-300 h-screen">*/}
                {/*    {selectedFilingDetails ? (*/}
                {/*        <>*/}
                {/*            <div*/}
                {/*                className="text-xs md:p-2 p-1 pl-4 md:m-2 m-1 bg-gray-100 border border-gray-300 cursor-pointer shadow-sm rounded-sm"*/}
                {/*                onClick={() => window.open(`/company/${ticker}/filing/${selectedFilingDetails.filing_type}/${selectedFilingDetails.fid}/exhibit/${selectedFilingDetails.seq}`, '_blank')}*/}
                {/*            >*/}
                {/*                Open filing in new window*/}
                {/*            </div>*/}
                {/*            {selectedFilingTextChunks.map((chunk, index) => (*/}
                {/*                <div*/}
                {/*                    key={index}*/}
                {/*                    className="text-xs md:p-3 p-1 md:m-2 m-1 bg-gray-200 border border-gray-300 cursor-pointer shadow-sm rounded-sm overflow-hidden truncate whitespace-pre-wrap"*/}
                {/*                    dangerouslySetInnerHTML={{ __html: chunk.text_content }}*/}
                {/*                    onClick={() => handleTextChunkClick(chunk)}*/}
                {/*                />*/}
                {/*            ))}*/}
                {/*        </>*/}
                {/*    ) : selectedFilingTextChunks.length === 0 ? (*/}
                {/*        <div className="text-xs text-center p-2 pl-4 m-3 bg-gray-100 border border-gray-300 shadow-sm rounded-sm ">*/}
                {/*            Select a filing to see search results.*/}
                {/*        </div>*/}
                {/*    ) : null}*/}
                {/*</div>*/}
                <SubscriptionModal
                    isOpen={showSubscriptionModal}
                    onClose={handleSubscriptionModalClose}
                    pageMessage={"Document Search available under our Basic subscription."}
                    subscriptionRequired={"Basic"}
                />

                <div className="flex-grow bg-white">
                    {selectedFilingDetails && (selectedFilingDetails.url.startsWith("/api/transcripts/") ?
                        <TranscriptIFrame
                            filingDoc={"/api/transcripts/" + selectedFilingDetails.document_id}
                            document_id={selectedFilingDetails.document_id}
                            exhibitNum={1}
                            menuVisible={menuVisible}
                            highlightSentence={selectedFilingDetails.highlightSentence}
                            highlightIDs={selectedFilingDetails.highlightIDs}
                            currentUser={currentUser}
                        />
                        :
                        <SECFilingIFrame
                            filingDoc={selectedFilingDetails.url}
                            cik={selectedFilingDetails.cik}
                            fid={selectedFilingDetails.fid}
                            eid={selectedFilingDetails.eid}
                            exhibitNum={selectedFilingDetails.exhibitNum || 0}
                            menuVisible={menuVisible}
                            highlightSentence={selectedFilingDetails.highlightSentence}
                            highlightIDs={selectedFilingDetails.highlightIDs}
                            currentUser={currentUser}
                        />
                    )}
                </div>
            </div>
        </div>

    );
}

export default React.memo(CompanyDocumentSearch);

