import React, { useState, useEffect, useCallback } from 'react';
import { API_URL } from "../../config";
import axiosInstance from '../../api';
import {format, parseISO} from 'date-fns';
import { DocumentTextIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import Spinner from "../Spinner/Spinner"
import { formatInTimeZone } from 'date-fns-tz';

function getEventType(event_type) {
    if (event_type === "M\u0026A Announcement") {
        return "M&A"
    } else if (event_type.includes("Update")) {
        return "Update"
    } else {
        return event_type
    }
}

const TranscriptCard = ({ transcript, ticker, isLastItem }) => {
    const navigate = useNavigate();

    const handleTranscriptClick = () => {
        navigate(`/company/${ticker}/transcript/${transcript.id}`);
    };

    const displayEventType = transcript.secondaryType || getEventType(transcript.event_type);

    return (
        <div
            className={`bg-filing-background border shadow-sm ${isLastItem ? 'border-b-2' : ''} border-gray-300 text-gray-700 hover:bg-gray-200 px-4 py-1 transition cursor-pointer`}
            onClick={handleTranscriptClick}
        >
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center gap-2 w-full">
                    <DocumentTextIcon className="h-4 w-4 text-gray-400" />
                    <div className="w-24 min-w-24 flex-shrink-0">
                        <span className="font-semibold">{displayEventType}</span>
                    </div>
                    <span className="text-gray-300">|</span>
                    <span className="flex-grow truncate text-ellipsis">{transcript.event_title}</span>
                    <span className="text-gray-500 ml-2">{transcript.audio_duration}</span>
                    <span className="text-gray-500 ml-2">{format(new Date(transcript.event_date), 'MM/dd/yyyy')}</span>
                </div>
                <ChevronRightIcon className="h-4 w-4 ml-2 text-gray-500" />
            </div>
        </div>
    );
};
const TranscriptCategory = ({ year, transcripts, ticker }) => {
    return (
        <div className="bg-gray-200 rounded mb-4">
            <h2 className="text-lg font-bold mb-2">{year}</h2>
            {transcripts.map((transcript, index) => (
                <TranscriptCard
                    key={transcript.id}
                    transcript={transcript}
                    ticker={ticker}
                    isLastItem={index === transcripts.length - 1}
                />
            ))}
        </div>
    );
};

const FutureEventCard = ({ event }) => {
    const displayEventType = event.secondaryType || getEventType(event.event_type);

    // Parse the UTC date
    const utcDate = parseISO(event.event_date);

    // Format the date and time in ET
    const formattedDate = formatInTimeZone(utcDate, 'America/New_York', 'MM/dd/yyyy');
    const formattedTime = formatInTimeZone(utcDate, 'America/New_York', 'h:mm a');

    return (
        <div className="bg-blue-100 border border-blue-300 text-blue-700 px-4 py-2 mb-2 rounded">
            <div className="flex items-center justify-between text-xs">
                <div className="flex items-center gap-2 w-full">
                    <span className="font-semibold">{displayEventType}</span>
                    <span className="text-blue-500">|</span>
                    <span className="flex-grow truncate text-ellipsis">{event.event_title}</span>
                    <span className="text-blue-500 ml-2">{formattedDate}</span>
                    <span className="text-blue-500 ml-2">{formattedTime} ET</span>
                </div>
            </div>
        </div>
    );
};

const Transcripts = ({ ticker, currentUser }) => {
    const [transcriptsByYear, setTranscriptsByYear] = useState({});
    const [futureEvents, setFutureEvents] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchTranscripts = useCallback(async () => {
        if (!ticker || !currentUser?.token) return;
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/api/company/transcripts/${ticker}`, {
                headers: { Authorization: `Bearer ${currentUser.token}` },
            });

            setFutureEvents(response.data.future_events || []);


            // Group transcripts by year
            const groupedTranscripts = response.data.transcripts.reduce((acc, transcript) => {
                const year = new Date(transcript.event_date).getFullYear();
                if (!acc[year]) {
                    acc[year] = [];
                }
                acc[year].push(transcript);
                return acc;
            }, {});

            setTranscriptsByYear(groupedTranscripts);
        } catch (err) {
            setError(err.toString());
        } finally {
            setIsLoading(false);
        }
    }, [ticker, currentUser?.token]);

    useEffect(() => {
        fetchTranscripts();
    }, [fetchTranscripts]);

    if (isLoading) return (
        <div className="flex justify-center items-center min-h-screen">
            <Spinner />
        </div>
    );
    if (error) return <div></div>;

    return (
        <div className="w-full mx-auto">
            <div className="bg-cover bg-center min-h-screen">
                <div className="mx-auto md:p-4 md:pt-2 p-1 pt-1">
                    {futureEvents && futureEvents.length > 0 && (
                        <div className="mb-6">
                            <h2 className="text-lg font-bold mb-2">Upcoming Events</h2>
                            {futureEvents.map((event, index) => (
                                <FutureEventCard key={index} event={event} />
                            ))}
                        </div>
                    )}
                    {Object.entries(transcriptsByYear)
                        .sort(([yearA], [yearB]) => yearB - yearA)
                        .map(([year, transcripts]) => (
                            <TranscriptCategory
                                key={year}
                                year={year}
                                transcripts={transcripts}
                                ticker={ticker}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Transcripts;