import React, {useEffect, useState} from 'react';
import FilingCardWithCompany from './FilingCards/FilingCardWithCompany';
import SpacerCard from '../Components/SpacerCard';
import LoadMorePortfolioFilingsButton from "./LoadMorePortfolioFilingsButton";
import axiosInstance from '../api';
import {API_URL} from "../config";

function PortfolioIndustryColumn({ filings = [], ticker , currentUser }) {
    const [allFilings, setAllFilings] = useState(filings);

    const today = new Date();
    today.setHours(0, 0, 0, 0); // setting hours, minutes, seconds and milliseconds to 0

    let lastLabel = null;

    const loadMoreFilings = async () => {
        try {
            const newFilings = await fetchMoreFilings();
            setAllFilings([...allFilings, ...newFilings]);
        } catch (error) {
            //
        }
    };

    const fetchMoreFilings = async () => {
        try {
            const res = await axiosInstance.get(`${API_URL}/api/company/facts/industry/filings/${ticker}?count=40&start=${allFilings.length}`);
            return res.data.filings
        } catch (error) {
            throw error; // Rethrow the error to be caught in loadMoreFilings
        }
    };

    useEffect(() => {
        // Update the state when the filings prop changes
        setAllFilings(filings);
    }, [filings]); // Add filings to the dependency array

    return (
        <div className="shadow rounded-sm border border-gray-400">
            <div className="">
                {allFilings.map((filing, index) => {
                    let filingDate = new Date(filing.updated);
                    filingDate.setHours(0, 0, 0, 0);

                    const diffTime = Math.abs(today - filingDate);
                    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                    let label = '';
                    if (diffDays < 1) {
                        label = "Today";
                    } else if (diffDays === 1) {
                        label = "Yesterday";
                    } else if (diffDays <= 7) {
                        label = "Last week";
                    } else if (diffDays <= 14) {
                        label = "Last two weeks";
                    } else if (diffDays <= 30) {
                        label = "Last month";
                    } else if (diffDays <= 90) {
                        label = "Last quarter";
                    } else if (diffDays <= 180) {
                        label = "Last 6 months";
                    } else if (diffDays <= 270) {
                        label = "Last 9 months";
                    } else if (diffDays <= 365) {
                        label = "Within Last year";
                    } else if (diffDays >= 365) {
                        label = "Over 1 year";
                    }

                    let content = [];
                    if (label !== lastLabel) {
                        content.push(<SpacerCard key={`spacer-${filingDate}`} text={label} />);
                        lastLabel = label;
                    }

                    content.push(
                        <FilingCardWithCompany
                            key={filing.accessionNumber}
                            filing={filing}
                            isLastItem={index === allFilings.length - 1}
                        />
                    );

                    return content;
                })}
                <LoadMorePortfolioFilingsButton numItems={allFilings.length} onLoadMore={loadMoreFilings}/>
            </div>
        </div>

    );
}

export default PortfolioIndustryColumn;
