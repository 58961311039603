import { useState, useEffect } from 'react';

const useSSE = (url) => {
    const [data, setData] = useState('');
    const [error, setError] = useState(null);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (!url) return;

        setData('');
        setError(null);
        setIsComplete(false);

        const eventSource = new EventSource(url);

        eventSource.onmessage = (event) => {
            console.log('SSE message:', event);
            setData(prevData => prevData + event.data);
        };

        eventSource.onerror = (event) => {
            console.log('SSE Error:', event);
            // Check if the error is due to the connection being closed
            if (eventSource.readyState === EventSource.CLOSED) {
                setIsComplete(true);
            } else {
                setError({ type: 'connection', message: 'Connection error' });
            }
            eventSource.close();
        };

        eventSource.addEventListener('open', (event) => {
            console.log('SSE Connection opened:', event);
        });

        // Listen for custom error events
        eventSource.addEventListener('error', (event) => {
            console.log('SSE Custom Error Event:', event);
            try {
                const errorData = JSON.parse(event.data);
                setError(errorData);
            } catch (e) {
                setError({ type: 'unknown', message: event.data || 'Unknown error occurred' });
            }
            eventSource.close();
        });

        eventSource.addEventListener('close', (event) => {
            console.log('SSE Close Event:', event);
            setIsComplete(true);
            eventSource.close();
        });

        return () => {
            eventSource.close();
        };
    }, [url]);

    return { data, error, isComplete };
};

export default useSSE;