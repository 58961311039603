import React, { useState, useEffect, useRef, useCallback } from 'react';
import "../../Components/SECFilingIFrame.css";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { SubscriptionModal } from "../SubscriptionModal/SubscriptionModal";
import { useSubscriptionModal } from "../SubscriptionModal/useSubscriptionModal";

const TranscriptIFrame = ({
                              filingDoc,
                              cik,
                              document_id,
                              menuVisible,
                              style,
                              highlightSentence,
                              highlightIDs,
                              currentUser
                          }) => {

    const proxyUrl = `/api/proxy/`;
    const [iframeContent, setIframeContent] = useState('');
    const iframeRef = useRef();
    const [iframeReady, setIframeReady] = useState(false);
    const navigate = useNavigate();
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    const [url, setUrl] = useState("")

    const fetchIframeContent = useCallback(async () => {
        if (!currentUser || !currentUser.token) {
            navigate('/login', {
                state: { redirectPath: `/Company/-/transcript/${document_id}` }
            });
            return;
        }

        let url = `${API_URL}${proxyUrl}${filingDoc}`;
        if (filingDoc.startsWith("/api/transcripts/")) {
            url = `${API_URL}${filingDoc}`;
        }

        setUrl(url)

        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${currentUser.token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 403) {
                handleSubscriptionModal();
            } else if (response.status === 401) {
                navigate('/login', {
                    state: { redirectPath: `/Company/-/transcript/${document_id}` }
                });
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const content = await response.text();
            setIframeContent(content);
        } catch (error) {
            console.error('Error fetching iframe content:', error);
        }
    }, [currentUser, proxyUrl, filingDoc, navigate, document_id, handleSubscriptionModal]);

    useEffect(() => {
        fetchIframeContent();
    }, [fetchIframeContent]);

    const sendMessageToIframe = useCallback((message, retryCount = 0) => {
        const iframe = iframeRef.current;
        if (iframe && iframeReady) {
            iframe.contentWindow.postMessage(message, '*');
        } else if (retryCount < 3) {
            setTimeout(() => sendMessageToIframe(message, retryCount + 1), 1000);
        } else {
        }
    }, [iframeReady]);

    const sendHighlightSentenceMessage = useCallback((nodeID, sentence) => {
        const message = {
            type: 'highlightSentence',
            sentence,
            nodeID,
        };
        sendMessageToIframe(message);
    }, [sendMessageToIframe]);

    useEffect(() => {
        if (highlightSentence) {
            const timer = setTimeout(() => {
                sendHighlightSentenceMessage(highlightIDs, highlightSentence);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [highlightSentence, highlightIDs, sendHighlightSentenceMessage]);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (!iframe) return;

        if (url === "") {
            return
        }

        const onLoad = () => {
            setIframeReady(true);

            console.log('Iframe ready, processing message queue. Url: ' + url);

            const message = {
                type: 'parentUrl',
                url: window.location.href,
                backend: API_URL + '/api/users/highlight',
                token: currentUser.token
            };

            iframe.contentWindow.postMessage(message, '*');

            //sendMessageToIframe({ type: 'currentUser', currentUser: currentUser || null });
            iframe.contentWindow.postMessage({ type: 'currentUser', currentUser: currentUser || null }, '*');

            console.log("document id: " + document_id)
            const exhibitData = {
                is_transcript: true,
                document_id: document_id,
                cik: "" + cik,
                fid: 0,
                eid: 0,
            };
            sendMessageToIframe({ type: 'exhibitData', exhibitData });

            if (window.location.hash) {
                iframe.srcdoc = iframeContent + `<script>window.location.hash = "${window.location.hash}";</script>`;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const hlStart = urlParams.get('hl_start');
            const hlEnd = urlParams.get('hl_end');

            if (hlStart && hlEnd) {
                iframe.contentWindow.postMessage({ type: 'highlightParams', hlStart, hlEnd }, '*');
            }
        };

        iframe.addEventListener('load', onLoad);

        return () => {
            iframe.removeEventListener('load', onLoad);
        };
    }, [iframeContent, url, currentUser, cik, document_id, sendMessageToIframe]);

    return (
        <div className={`iframe-container ${!menuVisible ? "menu-hidden" : ""}`} style={style}>
            <iframe
                allow="clipboard-write"
                srcDoc={iframeContent}
                title="Filing Content"
                ref={iframeRef}
            ></iframe>
            <SubscriptionModal
                isOpen={showSubscriptionModal}
                onClose={handleSubscriptionModalClose}
                pageMessage={"Transcripts available under our Basic subscription."}
                subscriptionRequired={"Basic"}
            />
        </div>
    );
};

export default TranscriptIFrame;