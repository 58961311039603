// Landing.js
import React from 'react';
import SearchBar from './SearchBar/SearchBar';
import NavBar from "./NavBar";
import Footer from "./Footer";
import LandingBackground from "./LandingBackground";

function Landing() {
    return ( <>
            <NavBar/>
            <LandingBackground>
                <div className="container mx-auto px-4 py-16 ">
                    <div className="mt-12"></div>
                    <div className="bg-nav-bar-theme border-2 border-white py-16">
                    <div className="text-center">
                        <div className="text-5xl md:text-4xl text-white font-bold mb-4">Company & Insider Search</div>
                        <div className="text-md md:block md:text-xl text-gray-300 mb-4 font-thin">SEC Filings, Insider Trades, Table Lookup, Industry Groups & More</div>
                        <div className="hidden md:block text-3xl md:text-4xl text-white mb-4">Find the <strong>Data</strong> you need to have an <strong>Edge</strong></div>
                        <div className="text-xl md:text-3xl font-bold text-white"></div>
                    </div>
                    <div>
                        <div className="mt-8">
                            <SearchBar />
                        </div>
                    </div>
                    </div>
                </div>
            </LandingBackground>
            <Footer/>
        </>
    )
}

export default Landing;