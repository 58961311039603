import React, { useState, useEffect } from "react";
import axiosInstance from '../api';
import { useAuth } from "../firebase/AuthContext";
import PortfolioColumn from "../Components/PortfolioColumn";
import CompanyColumn from "./CompanyColumn";
import { API_URL } from "../config";
import NavBarSmall from "./NavBarSmall";
import FooterSmall from "./FooterSmall";
import EmptyPortfolio from "./EmptyPortfolio";
import {useNavigate} from "react-router-dom";

function MainPortfolio() {
    const [portfolio, setPortfolio] = useState(null);
    const { currentUser } = useAuth();
    const [readyToNavigate, setReadyToNavigate] = useState(false); // New state
    const navigate = useNavigate();
    const [isDataEmpty, setIsDataEmpty] = useState(false);

    useEffect(() => {
        if (readyToNavigate && !currentUser) {
            navigate('/login');
        } else {
            console.log('User logged in');
        }
    }, [readyToNavigate, currentUser, navigate]);

    // Timer setup to change readyToNavigate after 3 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setReadyToNavigate(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        async function fetchMainPortfolio() {
            if (!currentUser || !currentUser.token) {
                return;
            }

            try {
                //console.log("API token:",currentUser.token);
                const headers = currentUser
                    ? { Authorization: `Bearer ${currentUser.token}` }
                    : {};
                const res = await axiosInstance.get(`${API_URL}/api/users/portfolio/main`, { headers });
                setPortfolio(res.data);

                if (Array.isArray(res.data) && res.data.length === 0) {
                    setIsDataEmpty(true);
                } else {
                    setIsDataEmpty(false);
                }
            } catch (error) {
                //
            } finally {
                //
            }
        }

        fetchMainPortfolio();

    }, [currentUser]);


    return (
        <div className="bg-filing-menu-background bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="flex flex-col bg-filing-menu-background min-h-screen">
                {isDataEmpty ? (
                        <EmptyPortfolio />
                    ) : portfolio && portfolio.companies && (
                    <div className="w-full bg-filing-menu-background">
                        <div className="flex flex-col md:flex-row justify-center items-start max-w-7xl mx-auto mt-8">
                            <div className="w-full md:w-3/4 lg:w-3/4 md:px-4 px-2 mb-8">
                                <h3 className="text-xl font-semibold mb-4">Recent Filings</h3>
                                {portfolio.filings && <PortfolioColumn filings={portfolio.filings} currentUser={currentUser} />}
                            </div>
                            <div className="w-full md:w-1/4 lg:w-1/4 md:px-4 px-1 mb-8">
                                <h3 className="text-xl font-semibold mb-4">Followed Companies</h3>
                                <CompanyColumn companies={portfolio.companies} />
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            <FooterSmall/>
        </div>
    );
}

export default MainPortfolio;
