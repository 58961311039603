import React, { useState } from 'react';
import './Arrow2.css';

const ArrowTableSearch = ({ onClick, initialState = false }) => {
    const [isOpen, setIsOpen] = useState(initialState);

    const handleClick = () => {
        setIsOpen(!isOpen);
        onClick();
    };


    return (
        <div className={"relative"}>
            <button
                className={`arrow-btn2 ${isOpen ? 'open' : 'closed'}`}
                onClick={handleClick}
                aria-label={isOpen ? 'Close menu' : 'Open menu'}
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
                >
                    <path
                        d="M15 18L9 12L15 6"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    );
};

export default ArrowTableSearch;