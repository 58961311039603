import React, { useState, useRef, useEffect } from 'react';
import useSSE from '../SSE/sse';
import { API_URL } from "../../config";
import LoginRequiredModal from "../YouMustBeLoggedIn/YouMustBeLoggedIn";
import { useSubscriptionModal } from "../SubscriptionModal/useSubscriptionModal"
// import FeedbackButtons  from "./FeedbackButtons";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import {CompanyQueryTips, LLMPhilosophy, LLMWarning} from "../ui/languageModelWarning";
import {SubscriptionModal} from "../SubscriptionModal/SubscriptionModal";

const SummarizeIcon = () => (
    <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2V8H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="currentColor"/>
        <path d="M12 14V13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const PulsingDot = () => (
    <span className="inline-block w-2 h-2 bg-blue-500 rounded-full animate-pulse"></span>
);

const AnimatedEllipsis = () => (
    <span className="inline-flex">
    <span className="animate-[pulse_1s_ease-in-out_infinite]">.</span>
    <span className="animate-[pulse_1s_ease-in-out_0.333s_infinite]">.</span>
    <span className="animate-[pulse_1s_ease-in-out_0.667s_infinite]">.</span>
  </span>
);

const Tooltip = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [arrowPosition] = 'top';
    const triggerRef = useRef(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (isVisible && triggerRef.current && tooltipRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            setPosition({
                top: triggerRect.bottom + window.scrollY - 100,
                left: triggerRect.left + window.scrollX + (triggerRect.width / 2) - (tooltipRect.width / 2)
            });
        }
    }, [isVisible]);

    return (
        <div className="relative inline-block">
            <div
                ref={triggerRef}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
                className="cursor-help"
            >
                {children}
            </div>
            {isVisible && (
                <div
                    ref={tooltipRef}
                    className="fixed z-50 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 max-w-[300px]"
                    style={{
                        top: `${position.top}px`,
                        left: `${position.left}px`,
                    }}
                >
                    {content}
                    <div
                        className={`absolute w-2 h-2 bg-gray-900 transform rotate-45 ${
                            arrowPosition === 'top' ? 'bottom-[-4px] left-[calc(50%-4px)]' : 'top-[-4px] left-[calc(50%-4px)]'
                        }`}
                    />
                </div>
            )}
        </div>
    );
};


const QueryOptions = ({ setDataSet }) => {
    const options = [
        {
            value: "current_data",
            label: "Default",
            description: "Target data: 8-Ks/6-Ks, Transcripts up to and including last Annual Filing. Slow."
        },
        {
            value: "transcript_data",
            label: "Transcript Default",
            description: "Query most important transcript data, may not include full text. Medium speed."
        },
        {
            value: "most_recent_transcript",
            label: "Most Recent Transcript Full Text",
            description: "Best for detailed queries on the latest transcript. Medium speed."
        },
        {
            value: "transcripts_full_text",
            label: "Transcripts Full Text (last 3 only)",
            description: "Queries full text of the last 3 transcripts, potentially weak on haystack-like queries. Relatively slow."
        }
    ];

    return (
        <div className="space-y-4">
            {options.map((option) => (
                <div key={option.value} className="flex items-center space-x-3">
                    <input
                        type="radio"
                        id={option.value}
                        name="dataSet"
                        value={option.value}
                        onChange={(e) => setDataSet(e.target.value)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label htmlFor={option.value} className="text-sm font-medium text-gray-900">
                        {option.label}
                    </label>
                    <Tooltip content={option.description}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                        </svg>
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};
const CompanyQueryInputV1 = ({ companyID, currentUser, refreshToken }) => {
    const [isStreaming, setIsStreaming] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [query, setQuery] = useState('');
    const [dataSet, setDataSet] = useState('current_data');
    const { data, error } = useSSE(streamUrl);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const modalRef = useRef(null);
    const summaryRef = useRef(null);
    const [displayData, setDisplayData] = useState('');
    const [localError, setLocalError] = useState(null);
    const { showSubscriptionModal, handleSubscriptionModal, handleSubscriptionModalClose } = useSubscriptionModal();
    // const [showFeedback, setShowFeedback] = useState(false);

    const handleSummarize = () => {
        if (!currentUser?.token) {
            setIsLoginModalOpen(true);
            return;
        }
        setDisplayData('')
        setLocalError(null);
        setIsStreaming(true);

        var dataId = 'current_data'
        if (dataSet === 'transcript_data') {
            dataId = 'tc'
        } else if (dataSet === 'transcripts_full_text') {
            dataId = 'tft'
        } else if (dataSet === 'current_data') {
            dataId = 'cd'
        } else if (dataSet ==='most_recent_transcript') {
            dataId = 'mrt'
        }

        setStreamUrl(`${API_URL}/api/company/query/llm/${companyID}?q=${query}&auth=${currentUser.token}&d=${dataId}`);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsStreaming(false);
        setStreamUrl('');
        setDisplayData('');
        setLocalError(null);
    };

    const closeLoginModal = () => {
        setIsLoginModalOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (summaryRef.current) {
            summaryRef.current.scrollTop = summaryRef.current.scrollHeight;
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setDisplayData(data);
        }
    }, [data]);

    useEffect(() => {
        if (localError) {
            setIsStreaming(false);
            switch(localError.type) {
                case 'forbidden':
                    handleSubscriptionModal();
                    setIsModalOpen(false);
                    break;
                case 'unauthorized':
                    setIsLoginModalOpen(true);
                    setIsModalOpen(false);
                    break;
                case 'connection':
                case 'unknown':
                default:
                    setDisplayData('');
                    break;
            }
        }
    }, [localError, handleSubscriptionModal]);

    // const handleFeedbackGiven = () => {
    //     console.log('Feedback given');
    // };

    useEffect(() => {
        if (error) {
            console.log('Component received error:', error);
            setLocalError(error);

            if (error.message === "Unauthorized") {
                console.log("User: " + currentUser.token)
                refreshToken()
            }
        }
    }, [error, currentUser, refreshToken]);

    return (
        <>
            <div className={"px-2"}>
                <div className={"pb-2"}><LLMWarning /></div>
                <div className={"pb-2"}><LLMPhilosophy /></div>
                <div className={"mb-2"}><CompanyQueryTips /></div>
                <div className="my-4">
                    <QueryOptions setDataSet={setDataSet}/>
                </div>
            </div>

            <div className="">
                <div className="flex space-x-2">
                    <Input
                        type="text"
                        placeholder="Query recent company documents..."
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="flex-grow rounded-sm"
                    />
                    <Button
                        onClick={handleSummarize}
                        disabled={isStreaming || !query.trim()}
                        className={`flex items-center justify-center px-4 py-2 text-white rounded-md transition-colors duration-300 ${
                            isStreaming || !query.trim() ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                        }`}
                    >
                        <SummarizeIcon />
                        <span className="ml-2">Query</span>
                    </Button>
                </div>
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div ref={modalRef} className="bg-white p-4 rounded-lg shadow-xl max-w-3xl w-full max-h-[90vh] flex flex-col">
                            <div className="flex items-center justify-between py-2 px-3 border-b border-gray-200">
                                <h4 className="text-sm font-semibold text-gray-700 flex items-center">
                                    {error ? 'Error Occurred' : 'Querying best LLM model'}
                                    {!displayData && !error && (
                                        <span className="ml-2">
                                            <PulsingDot />
                                            <AnimatedEllipsis />
                                        </span>
                                    )}
                                </h4>
                                <Button
                                    onClick={closeModal}
                                    variant="ghost"
                                    size="sm"
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </Button>
                            </div>

                            <div
                                ref={summaryRef}
                                className="flex-grow overflow-y-auto bg-gray-100 p-4 rounded-md"
                            >
                                {error ? (
                                    <div className="text-red-500">
                                        <p>Error Type: {error.type}</p>
                                        <p>Error Message: {error.message}</p>
                                    </div>
                                ) : (
                                    <div
                                        className="text-gray-900 text-sm text-left font-semibold leading-relaxed"
                                        dangerouslySetInnerHTML={{ __html: displayData || "Awaiting response..." }}
                                    />
                                )}
                            </div>

                            {/*{showFeedback && !error && (*/}
                            {/*    <FeedbackButtons*/}
                            {/*        response={displayData}*/}
                            {/*        user={currentUser}*/}
                            {/*        onFeedbackGiven={handleFeedbackGiven}*/}
                            {/*    />*/}
                            {/*)}*/}
                            <LLMWarning />
                        </div>
                    </div>
                )}
                <SubscriptionModal
                    isOpen={showSubscriptionModal}
                    onClose={handleSubscriptionModalClose}
                    subscriptionRequired={"Basic"}
                    pageMessage={"AI Language Model queries available under our Basic subscription."}
                />
                <LoginRequiredModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
            </div>
        </>
    );
};

export { CompanyQueryInputV1 };