import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../theme";

const FilingMenuExhibitList = ({ exhibits, ticker, type, sec_filing }) => {
    return (
        <>
            {exhibits.map((exhibit) => (
                <div
                    key={exhibit.id}
                    className={`${theme.colors.openDataBackground} hover:${theme.colors.openDataBackgroundHover} text-xs py-1 pl-2 pr-1 mb-1 border-l-2 border-r-gray-100 rounded-xs`}
                >
                    <Link
                        to={`/company/${ticker}/filing/${type}/${sec_filing.AccessionNumber}/exhibit/${exhibit.sequence}`}
                        className="text-white"
                    >
                        <div className="">
                            <div className="text-xs font-bold border-1 w-32">{exhibit.type}</div>
                            <div className="text-xs items-end">{exhibit.description}</div>
                        </div>
                    </Link>
                </div>
            ))}
        </>
    );
};

export default FilingMenuExhibitList;