import React from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyTickerBubbleField = ({ companies }) => {
    const navigate = useNavigate();

    const handleButtonClick = (ticker) => {
        navigate(`/company/${ticker[0]}`);
    };

    if (!companies || !Array.isArray(companies)) { // Render nothing if companies is not an array
        return null;
    }

    return (
        <>
            {companies.map((company) => (
                <div key={company.cik} className="relative group">
                    {
                        Array.isArray(company.ticker) &&
                        <>
                            <button
                                className="bg-filing-background hover:bg-green-600 text-filing-menu-text-color font-bold py-1 px-2 rounded text-sm border border-gray-400"
                                onClick={() => handleButtonClick(company.ticker)}
                            >
                                {company.ticker[0]}
                            </button>
                            <span className="absolute hidden group-hover:block bg-neutral-600 text-white text-xs rounded py-1 px-2 bottom-full whitespace-nowrap mb-1">
                                {company.name}
                            </span>
                        </>
                        }

                </div>
            ))}
        </>
    );
};

export default CompanyTickerBubbleField;