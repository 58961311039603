import React, { useState, useEffect, useCallback } from 'react';
import { API_URL } from "../../config";
import axiosInstance from '../../api';
import { format } from 'date-fns';
import { DocumentTextIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/Spinner'

const Transcripts = ({ ticker, currentUser }) => {
    const [transcripts, setTranscripts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchTranscripts = useCallback(async () => {
        if (!ticker || !currentUser?.token) return;
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/api/company/transcripts/${ticker}`, {
                headers: { Authorization: `Bearer ${currentUser.token}` },
            });
            setTranscripts(response.data);
        } catch (err) {
            setError(err.toString());
        } finally {
            setIsLoading(false);
        }
    }, [ticker, currentUser?.token]);

    useEffect(() => {
        fetchTranscripts();
    }, [fetchTranscripts]);

    if (isLoading) return <Spinner />;
    if (error) return <div>Error: {error}</div>;

    const handleTranscriptClick = (transcript) => {
        navigate(`/company/${ticker}/transcript/${transcript.id}`);
    };

    return (
        <div className="w-full mx-auto">
            <div className="bg-white shadow-sm rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Event</th>

                        <th className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                        <th className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {transcripts.map((transcript) => (
                        <tr
                            key={transcript.id}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleTranscriptClick(transcript)}
                        >
                            <td className="px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{format(new Date(transcript.event_date),  'MM/dd/yyyy')}</div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap">
                                <div className="flex items-center">
                                    <DocumentTextIcon className="h-5 w-5 text-gray-400 mr-2" />
                                    <div className="text-sm font-medium text-gray-900">{transcript.event_title}</div>
                                </div>
                            </td>

                            <td className="px-6 py-2 whitespace-nowrap">
                                <div className="text-sm text-gray-900">{transcript.secondaryType}</div>
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                                {transcript.audio_duration}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Transcripts;