import React from 'react';

const BackgroundWrapper = ({ children, className }) => {
    return (
        <div
            className={"bg-cover bg-center bg-gray-950 {className}"}
            style={{
                backgroundImage: "url('/hero-background-2.jpg')",
                backgroundSize: 'cover',
            }}
        >
            {children}
        </div>
    );
};

export default BackgroundWrapper;