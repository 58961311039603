// Auth.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../firebase/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmailPasswordModal from './EmailPasswordModal';

function Auth({ redirectPath = '/portfolio' }) {

    const { signInWithGoogle, signInWithTwitter, currentUser, error } = useAuth();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate(redirectPath);
        }
    }, [currentUser, navigate, redirectPath]);

    const handleGoogleLogin = async () => {
        try {
            await signInWithGoogle();
        } catch (err) {
            console.error("Error during Google login:", err);
        }
    };

    const handleTwitterLogin = async () => {
        try {
            await signInWithTwitter();
        } catch (error) {
            console.error("Error during Twitter login:", error);
        }
    };

    /*
    const handleEmailPasswordLogin = async (email, password) => {
        try {
            await firebaseApp.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
            console.error("Error during email/password login:", error);
        }
    };
    */
    const handleEmailLogin = () => {
        setShowModal(true);
    };

    return (
        <div className="h-screen text-white p-4 md:p-8 lg:p-8 flex items-center justify-center bg-opacity-0 ">

            <div className="bg-[#141313] rounded-lg overflow-hidden shadow-lg p-16 md:p-16 w-full sm:w-2/3 md:w-1/2 lg:w-1/3 border-white border-2">
                <h2 className="md:text-2xl lg:text-2xl sm:text-xl font-bold mb-1 md:mb-4 lg:mb-4 text-center">Login to Modeledge</h2>
                <div className="border border-gray-500 bg-black">

                </div>

                <div className="border border-gray-600 rounded-lg mt-4">
                    <div className="px-2 pt-2">
                        <button
                            onClick={handleGoogleLogin}
                            type="button"
                            className="text-white w-full  bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mx-auto mb-2"
                        >
                            <svg
                                className="mr-2 -ml-1 w-4 h-4"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="google"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 488 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                                ></path>
                            </svg>
                            Google<div></div>
                        </button>
                    </div>

                    <div className="px-2">
                        <button
                            onClick={handleTwitterLogin}
                            type="button"
                            className="text-white w-full bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-[#1DA1F2]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#1DA1F2]/55 mx-auto mb-2"
                        >
                            <svg
                                className="mr-2 -ml-1 w-4 h-4"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="twitter"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 132.684-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.244-14.292 20.791-32.161 39.308-52.628 54.253z"
                                ></path>
                            </svg>
                            Twitter<div></div>
                        </button>
                    </div>
                    <div className="px-2 mb-2">
                        <button
                            type="button"
                            className="text-white w-full text-center bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-gray-500/50 font-medium rounded-lg text-sm px-5 py-2.5 items-center justify-between dark:focus:ring-gray-500/55 mx-auto"
                            onClick={handleEmailLogin}
                        >

                            Login with Email
                        </button>
                    </div>

                    {showModal &&
                        <EmailPasswordModal closeModal={() => setShowModal(false)} error={error} />}

                </div>
                <div className="px-2 mt-4">
                    <button
                        type="button"
                        className="text-white w-full bg-[#333333] hover:bg-[#000000]/90 focus:ring-4 focus:outline-none focus:ring-gray-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center justify-between dark:focus:ring-gray-500/55 mx-auto"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                </div>

            </div>
        </div>
    );
}

export default Auth;