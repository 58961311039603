import axios from 'axios';
import {API_URL, GO_SERVERLESS_API_URL} from './config';

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    baseURL: API_URL,
});

const checkBackupServerless = async (error) => {
    const originalUrl = error.config.url;
    if (originalUrl.startsWith(API_URL)) {
        const backupUrl = GO_SERVERLESS_API_URL + originalUrl.slice(API_URL.length);
        try {
            return await axios.request({
                ...error.config,
                url: backupUrl,
            });
        } catch (backupError) {
            console.error('Backup serverless request failed:', backupError);
            throw backupError;
        }
    } else {
        throw error;
    }
};

const showAlert = (message) => {
    alert(message);
};

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response) {
            const {status, data} = error.response;
            console.error('API Error:', data);

            switch (status) {
                case 401:
                case 403:
                    return Promise.reject(error);
                case 400:
                    showAlert('Unknown request - please try something else');
                    break;
                case 404:
                    showAlert('Oops, nothing found - please try another query');
                    break;
                case 500:
                case 502:
                case 503:
                case 504:
                    showAlert('We experienced an error, please try again later');
                    break;
                default:
                    showAlert('We were unable to handle your request, please try again later');
            }
        } else if (error.request) {
            console.error('No response received:', error.request);
            try {
                return await checkBackupServerless(error);
            } catch (backupError) {
                console.error('Backup serverless request failed:', backupError);
                showAlert('Backup serverless request failed');
            }
        } else {
            console.error('Error setting up the request:', error.message);
            showAlert('Error setting up the request');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;