import React, { useState, useEffect } from 'react';
import axiosInstance from '../api';
import TableSearchWithModal from "./TableSearchInput/TableSearchWithModal";
import { useParams } from "react-router-dom";
import { useAuth } from "../firebase/AuthContext";
import CompanyButton from '../Components/CompanyButton/CompanyButtonV4';
import { API_URL } from "../config";
import Logo from "./Logo";
import ArrowTableSearch from "./Arrow/ArrowTableSearchV2";
import FilingList from "./FilingCardsColumn/FilingCardColumnTableSearch";

function CompanyTableSearch() {
    const { ticker, table_id } = useParams();
    const [exhibitWithTables, setExhibitWithTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState(null);
    const [selectedExhibitId, setSelectedExhibitId] = useState(null);
    const [company, setCompany] = useState(null);
    const { currentUser } = useAuth();
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768);

    useEffect(() => {
        const headers = currentUser
            ? { Authorization: `Bearer ${currentUser.token}` }
            : {};

        axiosInstance
            .get(`${API_URL}/tables/series/filings?id=${table_id}`, { headers })
            .then((response) => {
                const responseData = response.data;
                if (responseData && responseData.results && Array.isArray(responseData.results)) {
                    const exhibits = responseData.results.map(result => result.ExhibitWithTables);
                    setExhibitWithTables(exhibits);
                    setCompany(responseData.company);

                    let found = false;
                    for (const exhibitWithTable of exhibits) {
                        for (const table of exhibitWithTable.Tables) {
                            if (table.id.toString() === table_id) {
                                setSelectedTable(table);
                                setSelectedExhibitId(exhibitWithTable.Exhibit.id);
                                found = true;
                                break;
                            }
                        }
                        if (found) break;
                    }

                    if (!found) {
                        console.error("Table not found for given ID:", table_id);
                        setSelectedTable(null);
                        setSelectedExhibitId(null);
                    }
                } else {
                    console.error("Unexpected response structure:", responseData);
                    setSelectedTable(null);
                    setSelectedExhibitId(null);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setSelectedTable(null);
                setSelectedExhibitId(null);
            });
    }, [table_id, currentUser]);

    const openFiling = () => {
        if (!selectedExhibit || !selectedTable) {
            console.error("Selected exhibit or table is null");
            return;
        }

        const filingUrl = `/company/${ticker}/filing/${selectedExhibit.Exhibit.edges.sec_filing.filing_type}/${selectedExhibit.Exhibit.edges.sec_filing.accession_number}`;
        const tableIndex = selectedTable.html_table_index ? `#table-${selectedTable.html_table_index}` : '';

        window.open(`${filingUrl}${tableIndex}`, "_blank");
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const formatFilingDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear().toString().substr(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${month}/${day}/${year}`;
    };


    const selectedExhibit = exhibitWithTables.find(exhibit => exhibit.Exhibit.id === selectedExhibitId);

    const handleExhibitSelect = (exhibitWithTable) => {
        setSelectedExhibitId(exhibitWithTable.Exhibit.id);
        setSelectedTable(exhibitWithTable.Tables[0]);
    };

    return (
        <div className="flex flex-col min-h-screen bg-filing-menu-background pl-2">
            <div className="flex flex-row flex-grow">
                <div className={`transition-all duration-300 ease-in-out ${menuVisible ? 'w-80' : 'w-4'} overflow-y-auto overflow-x-hidden h-screen `}>
                    <ArrowTableSearch onClick={toggleMenu} />
                    <div className="flex justify-center items-center text-black my-4">
                        <Logo menuOpened={menuVisible} className="mr-6 ml-2" />
                    </div>
                    <div className={"rounded-sm mb-2 px-1"}>
                        <CompanyButton  company={company} ticker={ticker}/>
                    </div>
                    <div className="p-4">
                        <TableSearchWithModal ticker={ticker} />
                        <div className={"my-2"}>
                            <h2 className="text-gray-950 font-semibold">Results</h2>
                        </div>
                        <FilingList  handleFilingClick={handleExhibitSelect} filingData={exhibitWithTables} />
                    </div>
                </div>

                <div className="flex-1 min-h-screen bg-white text-black p-5 md:pr-10 overflow-x-auto">
                    {
                        selectedExhibit && selectedExhibit.Tables.map((table, index) => (
                            <div key={`${table.id}-${index}`} className="mb-4">
                                <div>
                                    <h4 className="text-xl font-bold">
                                        From {selectedExhibit.Exhibit.document_type} ({formatFilingDate(selectedExhibit.Exhibit.filing_date)})
                                    </h4>
                                    <p className="text-sm py-1 px-1">...{table.text}</p>
                                </div>
                                <div className="clear-style border border-gray-300 rounded-sm p-3" dangerouslySetInnerHTML={{ __html: table.html }} />
                                <button
                                    onClick={openFiling}
                                    className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 mt-2 rounded"
                                >
                                    Open Filing
                                </button>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );

}

export default CompanyTableSearch;
