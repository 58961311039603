// Home.js
import React, {useEffect} from 'react';
import {useAuth} from "../firebase/AuthContext";
import {useNavigate} from "react-router-dom";
import Landing from "./Landing";

function Home() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            navigate('/portfolio')
        }
    }, [currentUser, navigate]);


    return (
        <Landing />
    )
}

export default Home;