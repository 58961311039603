import React from "react";

const FilingMenuSecondaryData = ({ sec_filing }) => {
    return (
        <div className="px-3 py-1 bg-gray-600 border-l-2 border-l-gray-400 shadow-lg">
            <div className="px-3 pb-1 flex justify-between ">
                <span className="text-sm text-gray-100">Filing Type:</span>
                <span className="text-sm text-gray-100">
          {sec_filing.FilingType}
        </span>
            </div>
            <div className="px-3 pb-1 flex justify-between ">
                <span className="text-sm text-gray-100">Filing Date:</span>
                <span className="text-sm text-gray-100">
          {new Date(sec_filing.FilingDate).toLocaleDateString()}
        </span>
            </div>
            <div className="px-3 flex justify-between ">
                <span className="text-sm text-gray-100">Updated:</span>
                <span className="text-sm text-gray-100">
          {new Date(sec_filing.Updated).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
          })}
        </span>
            </div>
        </div>
    );
};

export default FilingMenuSecondaryData;