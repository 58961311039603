import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Ensure you're importing useNavigate

function DocumentSearchBarV3({ ticker }) {
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate(); // Using the useNavigate hook

    const search = () => {
        // Perform navigation
        navigate(`/company/search/${ticker}?query=${searchTerm}`);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            search();
        }
    };

    // Adjusted to call the search function directly
    const handleSearchClick = () => {
        search();
    };

    return (
        <div>
            <div className="flex items-center bg-filing-menu-background rounded text-sm border border-gray-400 py-1 h-10 relative max-w-4xl min-w-full mx-auto" >
                <input
                    type="text"
                    placeholder="Document search"
                    className="border-none bg-gray-100 text-gray-600 text-sm p-1 px-1 mx-1 outline-none w-full rounded-sm h-9"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <button
                    className="bg-company-button hover:bg-code-light-black text-white text-xs font-bold py-1 px-1 m-1 rounded"
                    onClick={handleSearchClick} // Changed to call handleSearchClick
                >
                    Search
                </button>
            </div>
        </div>
    );
}

export default DocumentSearchBarV3;