// File: @/components/ui/input.js
import React from 'react';

export const Input = ({ className, ...props }) => {
    return (
        <input
            className={`px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-neutral-900 ${className}`}
            {...props}
        />
    );
};