import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import FooterSmall from "./FooterSmall";
import { useAuth } from "../firebase/AuthContext";
import NavBarSmall from "./NavBarSmall";
import { API_URL } from '../config.js';
import FilingCategory from "./FilingCatagory";
import FilingCategoryOwnershipFilings from "./FilingCatagoryOwnership";
import ModeledgeName from "./Modeledge";
import NavBarSmallV2 from "./NavBarSmallV2";
import SimilarCompaniesV2 from "./SimilarCompaniesV2";
import DocumentSearchBarV3 from "./DocumentSearchV3";
import CompanyDescription from "./CompanyQuery/CompanyDescription";
import Transcripts from "./Transcript/TranscriptPage";
import TransactionsTableTGGroup from "./InsiderTransactions/InsidersTGGroup";
import { arrayToStringWithCommasAndLengthLimitIntelligent } from "../values";
import TableSearchWithModalV2 from "./TableSearchInput/TableSearchWithModalV2";

function CompanyV2() {
    const [activeTab, setActiveTab] = useState('Filings');
    const [companyData, setCompanyData] = useState(null);
    const [filingsData, setFilingsData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const { ticker } = useParams();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const fetchCompanyData = useCallback(async () => {
        try {
            const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};
            const response = await axiosInstance.get(`${API_URL}/api/company/simple/${ticker}`, { headers });
            setIsFollowing(response.data.isFollowing);
            setCompanyData(response.data.company);
        } catch (error) {
            console.error('Error fetching company data:', error);
            // Consider adding user-facing error handling
        }
    }, [ticker, currentUser]);

    const fetchFilingsData = useCallback(async () => {
        try {
            const headers = currentUser ? { Authorization: `Bearer ${currentUser.token}` } : {};
            const response = await axiosInstance.get(`${API_URL}/api/company/filings/${ticker}`, { headers });
            setFilingsData(response.data.filings);
        } catch (error) {
            console.error('Error fetching filings data:', error);
            // Consider adding user-facing error handling
        }
    }, [ticker, currentUser]);

    useEffect(() => {
        fetchCompanyData();
        fetchFilingsData();
    }, [fetchCompanyData, fetchFilingsData]);

    const handleFollowToggle = async () => {
        if (!currentUser) return navigate('/login');
        try {
            const headers = { Authorization: `Bearer ${currentUser.token}` };
            const url = `${API_URL}/api/users/portfolio/${isFollowing ? 'remove' : 'add'}/main/${ticker}`;
            const response = await axiosInstance.post(url, {}, { headers });
            setIsFollowing(response.data.isFollowing);
        } catch (error) {
            console.error('Error updating follow status:', error);
            // Consider adding user-facing error handling
        }
    };

    const categories = [
        { key: 'News', component: FilingCategory },
        { key: 'Financial', component: FilingCategory },
        { key: 'Prospectus', component: FilingCategory },
        { key: 'Proxies', component: FilingCategory },
        { key: 'Ownership', component: FilingCategoryOwnershipFilings },
        { key: 'SEC Correspondence', component: FilingCategory },
    ];

    const renderFilingCategories = () => {
        if (filingsData && typeof filingsData === 'object' && Object.keys(filingsData).length > 0) {


            return categories.map(({ key, component: Component }) => (
                <Component
                    key={`${key}_${ticker}_${filingsData[key]?.length}`}
                    title={key}
                    filings={filingsData[key] || []}
                    ticker={ticker}
                    currentUser={currentUser}
                />
            ));
        }

        return categories.map(({ key }) => (
            <div key={key} className="bg-white p-4 rounded shadow">
                <h2 className="text-lg font-bold mb-2">{key}</h2>
            </div>
        ));
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Filings':
                return (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-gray-100 rounded-lg shadow-lg">
                        {renderFilingCategories()}
                    </div>
                );
            case 'Transcripts':
                return (
                    <div className="p-4 bg-gray-100 rounded-lg shadow-lg">
                        <Transcripts ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            case 'Insider Transactions':
                return (
                    <div className="p-4 bg-gray-100 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Insider Transactions - Grouped by filing</h2>
                        <TransactionsTableTGGroup ticker={ticker} currentUser={currentUser} />
                    </div>
                );
            default:
                return null;
        }
    };

    if (!companyData) return <NavBarSmall />; // Consider adding a proper loading state

    return (
        <div className="flex min-h-screen bg-gray-50">
            <aside className="w-64 bg-code-near-black text-white" aria-label="Sidebar">
                <div className="px-6 py-4">
                    <a href="/portfolio" className="flex items-center space-x-2">
                        <img src="/images/logo.png" alt="PocketBase logo" className="w-8 h-8" />
                        <ModeledgeName />
                    </a>
                </div>
                <nav className="mt-6 px-6 space-y-4">
                    <div>
                        <h3 className="text-sm font-semibold mb-2">Search tables</h3>
                        <TableSearchWithModalV2 ticker={ticker} />
                    </div>
                    <div>
                        <h3 className="text-sm font-semibold mb-2">Search in documents</h3>
                        <DocumentSearchBarV3 ticker={ticker} />
                    </div>
                    <div>
                        <button
                            className="bg-orange-700 text-white rounded px-4 py-2 text-sm w-full hover:bg-orange-600 transition-colors"
                            onClick={() => navigate(`/company/insider/${ticker}`)}
                        >
                            Insider Transactions
                        </button>
                    </div>
                    <div>
                        <h3 className="text-sm font-semibold mb-2">Similar companies</h3>
                        <SimilarCompaniesV2 ticker={ticker} />
                    </div>
                </nav>
            </aside>
            <div className="flex-1">
                <NavBarSmallV2 />
                <main className="p-6">
                    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <h1 className="text-3xl font-bold text-gray-900">
                                {companyData.name}
                                {companyData.tickers && (
                                    <span className="text-gray-600 ml-2 text-xl">
                                        ({arrayToStringWithCommasAndLengthLimitIntelligent(companyData.tickers)})
                                    </span>
                                )}
                            </h1>
                            <button
                                className={`px-4 py-2 rounded-full text-sm font-semibold ${
                                    isFollowing ? 'bg-gray-200 text-gray-700' : 'bg-blue-600 text-white'
                                } hover:opacity-80 transition-opacity`}
                                onClick={handleFollowToggle}
                            >
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                        </div>
                        <p className="text-sm text-gray-600 mb-4">{companyData.businessCity}, {companyData.businessState}</p>
                        <CompanyDescription ticker={ticker}/>
                    </div>
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                        <div className="flex border-b border-gray-200">
                            {['Filings', 'Transcripts', 'Insider Transactions'].map((tab) => (
                                <button
                                    key={tab}
                                    className={`py-4 px-6 font-medium text-sm focus:outline-none transition-colors ${
                                        activeTab === tab
                                            ? 'border-b-2 border-blue-600 text-blue-600 bg-gray-50'
                                            : 'text-gray-600 hover:text-gray-800 hover:bg-gray-50'
                                    }`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                        <div className="p-6">
                            {renderTabContent()}
                        </div>
                    </div>
                </main>
                <footer className="p-4">
                    <FooterSmall />
                </footer>
            </div>
        </div>
    );
}

export default CompanyV2;