export const arrayToStringWithCommasAndLengthLimit = (array, maxLength) => {
    if (!Array.isArray(array) || array.length === 0) {
        return '';
    }

    let result = array[0];
    let currentLength = result.length;

    for (let i = 1; i < array.length; i++) {
        const nextItem = array[i];
        const nextLength = currentLength + nextItem.length + 2; // +2 for ', '

        if (nextLength <= maxLength) {
            result += ', ' + nextItem;
            currentLength = nextLength;
        } else {
            result += '...';
            break;
        }
    }

    return result;
};


export const shortenStringByRemovingAnnounces = (str) => {
    if (typeof str !== 'string') {
        return str;
    }
    return str.replace(/\b(Announces)\b/gi, '').trim();
};