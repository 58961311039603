import React from 'react';
import NavBarSmall from "./NavBarSmall";
import FooterSmall from "./FooterSmall";
import RecentFilingsEmptyPortfolioWithCount from "./RecentFilings";

function NotFoundPage() {
    return (
        <div className="bg-gray-300 bg-cover bg-center min-h-screen">
            <NavBarSmall />
            <div className="flex flex-col bg-gray-300 min-h-screen">
                <div className="text-center bg-cover p-4">
                    <div className="w-full bg-company-data-background pb-8">
                        <div className="mx-auto max-w-fit">
                            <div className="flex flex-col justify-between items-center p-6">
                                <div className="text-3xl font-bold p-2 border-2 border-gray-600 rounded-sm">404</div>
                                <div className="text-2xl font-bold pt-6">The page you are looking for doesn't exist or has been moved</div>
                            </div>
                        </div>
                        <div className="md:w-3/5 w-11/12 mx-auto pt-6">
                            <RecentFilingsEmptyPortfolioWithCount portfolioName={"empty"} n={2000} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterSmall/>
        </div>
    );
}

export default NotFoundPage;