import React, { useState, useEffect } from "react";
import axiosInstance from '../api';
import { API_URL } from "../config";
import PortfolioIndustryColumn from "./PortfolioIndustryColumn";
import CompanyTickerRow from "./CompanyTickerRow";

function RecentFilingsIndustryGroupByTicker( {ticker}) {
    const [portfolio, setPortfolio] = useState(null);

    useEffect(() => {
        async function fetchEmptyPortfolioFill() {
            try {
                const res = await axiosInstance.get(`${API_URL}/api/company/facts/industry/filings/${ticker}`);
                console.log("API response:", res.data);
                console.log("Length:", res.data.length);
                console.log("isArray:", Array.isArray(res.data));
                setPortfolio(res.data);
            } catch (error) {
                console.error("Error fetching main portfolio:", error);
            } finally {
                console.log("finally")
            }
        }
        fetchEmptyPortfolioFill();
    }, [ticker]);


    return (
        <>
            {portfolio && portfolio.companies && <CompanyTickerRow companies={portfolio.companies} /> }
        <div className="">
            {portfolio && portfolio.filings && <PortfolioIndustryColumn filings={portfolio.filings} ticker={ticker} />}
        </div>
        </>
    );
}

export default RecentFilingsIndustryGroupByTicker;
