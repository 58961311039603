import React, { useState } from 'react';
import { useAuth } from '../firebase/AuthContext';
import BackgroundWrapper from "./LandingBackground";


const EmailPasswordModal = ({ closeModal, error }) => {
    const { signUp, signIn, clearError } = useAuth();
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isLogin) {
                await signIn(email, password);
            } else {
                await signUp(email, password);
            }
            resetState();
            closeModal();
        } catch (error) {
            console.error('Error with email/password authentication:', error);
        }
    };


    const resetState = () => {
        setEmail('');
        setPassword('');
        clearError();
    };

    return (
        <BackgroundWrapper>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-100 overflow-y-auto h-full w-full flex items-center justify-center" style={{
            backgroundImage: `url('/hero-background-2.jpg')`,
            backgroundSize: 'cover',
        }}>
            <div className="mx-auto p-5 border w-96 shadow-lg rounded-md bg-[#141313]">
                <div className="mt-3 text-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-300">
                        {isLogin ? 'Login' : 'Sign Up'} with Email
                    </h3>
                    <form onSubmit={handleSubmit} className="mt-2 px-7 py-3">
                        <input
                            type="email"
                            placeholder="Email"
                            className="mb-3 px-3 py-2 border border-gray-500 rounded-lg w-full bg-black text-white"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="mb-3 px-3 py-2 border border-gray-500 rounded-lg w-full bg-black text-white"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button
                            type="submit"
                            className="w-full bg-[#333333] hover:bg-[#000000]/90 text-white rounded-lg px-4 py-2"
                        >
                            {isLogin ? 'Login' : 'Sign Up'}
                        </button>
                    </form>
                    {error && <p className="text-red-500">{error.message}</p>}
                    <button
                        onClick={() => setIsLogin(!isLogin)}
                        className="text-sm text-blue-500 hover:underline mt-2"
                    >
                        {isLogin ? 'Need an account? Sign Up' : 'Already have an account? Login'}
                    </button>
                    <div className="mt-2">
                        <button
                            onClick={() => { resetState(); closeModal(); }}
                            className="text-sm text-gray-500 hover:underline"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </BackgroundWrapper>
    );
};

export default EmailPasswordModal;
