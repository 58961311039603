import React from 'react';
import { Link } from 'react-router-dom';
import LeftArrowIcon from "./left-arrow.svg";

const CompanyButton = ({ company, ticker }) => {
    const displayName = company?.name || '';
    const displayTicker = ticker ? `(${ticker})` : '';

    return (
        <Link
            to={`/company/${ticker}`}
            className="block text-center transition-colors duration-200 text-gray-200 font-semibold"
        >
            <div className="border-l-2 border-l-gray-400 flex items-center justify-between px-3 py-1 text-sm text-gray-200 bg-gray-600 border-transparent hover:border-gray-200 hover:shadow-2xl hover:bg-gray-700 hover:text-gray-200 focus:bg-blue-100 focus:text-blue-800">
                <img src={LeftArrowIcon} alt="Load More" className="w-3 h-3" />

                <span className="hidden md:inline ml-1">
                    {displayName} {displayTicker}
                </span>

                <span className="md:hidden">
                    {ticker || displayName}
                </span>
            </div>
        </Link>
    );
};

export default CompanyButton;