import { FaTwitter } from 'react-icons/fa';

const FooterSmall = () => {
    return (
        <footer className="bg-gray-100 p-3 border-t border-gray-200 text-xs">
            <div className="container mx-auto flex justify-between">
                <p className="text-gray-600">
                    &copy; 2024 Modeledge
                </p>

                <div>
                    <a className="text-gray-600 hover:text-gray-800 mr-3" href="/about">About</a>
                    <a className="text-gray-600 hover:text-gray-800 mr-3" href="/contact">Contact</a>
                    <a className="text-gray-600 hover:text-gray-800 mr-3" href="/terms">Terms</a>
                    <a className="text-gray-600 hover:text-gray-800 mr-3" href="/privacy">Privacy</a>
                </div>

                <a
                    href="https://twitter.com/FwdQuarter"
                    className="text-gray-600 hover:text-gray-800"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTwitter size={20} />
                </a>
            </div>
        </footer>
    );
};

export default FooterSmall;