import React from 'react';
import { Link } from 'react-router-dom';
import RecentFilingsEmptyPortfolio from "./RecentFilingsEmptyPortfolio";

const EmptyPortfolio = () => {
    return (
        <div className="text-center bg-cover p-2">
            <div className="text-center p-4 bg-company-data-background ">
                <div className="text-xl font-bold p-4">Your portfolio is empty!</div>
                <div className="p-4">Follow companies to see their recent filings</div>

                <div className="flex flex-col sm:flex-row justify-center space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 mt-4">
                    <Link to="/company/AAPL" className="bg-nav-bar-theme hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Apple
                    </Link>
                    <Link to="/company/GOOGL" className="bg-nav-bar-theme hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Alphabet
                    </Link>
                    <Link to="/company/MSFT" className="bg-nav-bar-theme hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Microsoft
                    </Link>
                    <Link to="/company/AMZN" className="bg-nav-bar-theme hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Amazon
                    </Link>
                    <Link to="/company/META" className="bg-nav-bar-theme hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                        Meta
                    </Link>
                </div>
            </div>
            <div className="w-full bg-company-data-background pb-8">
                <div className="text-xl font-bold p-4">Recent Filings</div>
                 <div className="md:w-3/5 w-11/12  mx-auto">
                     <RecentFilingsEmptyPortfolio portfolioName={"empty"} />
                 </div>
            </div>
        </div>
    );
};

export default EmptyPortfolio;